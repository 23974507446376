export const CollaboratorIcon = ({ height = 22, width = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.25 15C20.94 15 21.5 14.44 21.5 13.75C21.5 13.06 20.94 12.5 20.25 12.5H11.5V11.5H18.25C18.94 11.5 19.5 10.94 19.5 10.25C19.5 9.58 18.97 9.05 18.32 9.01L8.37 9L9.85 6.4C9.94 6.23 9.99 6.06 9.99 5.86C9.99 5.6 9.9 5.36 9.73 5.16L8.62 4L1.44 10.8C0.84 11.36 0.5 12.15 0.5 12.97V19C0.5 20.66 1.84 22 3.5 22H17.25C17.94 22 18.5 21.44 18.5 20.75C18.5 20.06 17.94 19.5 17.25 19.5H11.5V18.5H19.25C19.94 18.5 20.5 17.94 20.5 17.25C20.5 16.56 19.94 16 19.25 16H11.5V15H20.25ZM9.5 20H3.5C2.95 20 2.5 19.55 2.5 19V13C2.5 12.61 2.73 12.36 2.86 12.25L6.5 8.87V11H9.5V20ZM13 8C13.83 8 14.5 7.33 14.5 6.5C14.5 5.66 13 4 13 4C13 4 11.5 5.66 11.5 6.5C11.5 7.33 12.17 8 13 8ZM18 0C18 0 15.5 2.83 15.5 4.5C15.5 5.88 16.62 7 18 7C19.38 7 20.5 5.88 20.5 4.5C20.5 2.83 18 0 18 0ZM18 5.5C17.45 5.5 17 5.05 17 4.5C17 4.1 17.43 3.28 18 2.45C18.57 3.28 19 4.1 19 4.5C19 5.05 18.55 5.5 18 5.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
