import { memo } from "react";
import * as icons from "./svg";

const Icon = memo(({ name, height, width }) => {
  if (name === "back") return <icons.BackIcon height={height} width={width} />;
  if (name === "panel")
    return <icons.PanelIcon height={height} width={width} />;
  if (name === "session")
    return <icons.SessionIcon height={height} width={width} />;
  if (name === "solicitudes")
    return <icons.SolicitudesIcon height={height} width={width} />;
  if (name === "user") return <icons.UserIcon height={height} width={width} />;
  if (name === "users")
    return <icons.UsersIcon height={height} width={width} />;
  if (name === "downChevron")
    return <icons.DownChevron height={height} width={width} />;
  if (name === "info") return <icons.InfoI height={height} width={width} />;
  if (name === "house")
    return <icons.HouseIcon height={height} width={width} />;
  if (name === "config")
    return <icons.ConfigIcon height={height} width={width} />;
  if (name === "support")
    return <icons.SupportIcon height={height} width={width} />;
  if (name === "vitalSign")
    return <icons.VitalSignIcon height={height} width={width} />;
  if (name === "EmailIcon")
    return <icons.EmailIcon height={height} width={width} />;
  if (name === "PhoneIcon")
    return <icons.PhoneIcon height={height} width={width} />;
  if (name === "VendorIcon")
    return <icons.VendorIcon height={height} width={width} />;
  if (name === "CollaboratorIcon")
    return <icons.CollaboratorIcon height={height} width={width} />;
  if (name === "reloadIcon")
    return <icons.ReloadIcon height={height} width={width} />;
  if (name === "passIconCheck")
    return <icons.PassIconCheck height={height} width={width} />;
  if (name === "passIconInfo")
    return <icons.PassIconInfo height={height} width={width} />;
  if (name === "passIconError")
    return <icons.PassIconError height={height} width={width} />;
  if (name === "") return <icons.AdverIcon height={height} width={width} />;
  if (name === "Dashboard")
    return <icons.DashboardIcon height={height} width={width} />;
  if (name === "Tramites")
    return <icons.TramitesIcon height={height} width={width} />;
  if (name === "Check")
    return <icons.CheckIcon height={height} width={width} />;
  if (name === "DiagonalUp")
    return <icons.DiagonalUpIcon height={height} width={width} />;
  if (name === "UploadIcon")
    return <icons.UploadIcon height={height} width={width} />;
  if (name === "TrashIcon")
    return <icons.TrashIcon height={height} width={width} />;
  if (name === "UploadCloud")
    return <icons.UploadCloud height={height} width={width} />;
  if (name === "BackArrow")
    return <icons.BackArrow height={height} width={width} />;
  if (name === "Log") return <icons.LogIcon height={height} width={width} />;
  if (name === "Lock") return <icons.LockIcon height={height} width={width} />;
  if (name === "StepFinancialIcon")
    return <icons.StepFinancialIcon height={height} width={width} />;
  if (name === "StepLaboralDataIcon")
    return <icons.StepLaboralDataIcon height={height} width={width} />;
  if (name === "StepPassportIcon")
    return <icons.StepPassportIcon height={height} width={width} />;
  if (name === "StepPepIcon")
    return <icons.StepPepIcon height={height} width={width} />;
  if (name === "StepsDataIcon")
    return <icons.StepsDataIcon height={height} width={width} />;
  if (name === "StepsFaceIcon")
    return <icons.StepsFaceIcon height={height} width={width} />;
  if (name === "TinCheck")
    return <icons.TinCheck height={height} width={width} />;
  if (name === "TinChevron")
    return <icons.TinChevron height={height} width={width} />;
  if (name === "CopyIcon")
    return <icons.CopyIcon height={height} width={width} />;
  if (name === "StepsHouseIcon")
    return <icons.StepsHouseIcon height={height} width={width} />;
  if (name === "PencilIcon")
    return <icons.PencilIcon height={height} width={width} />;
  if (name === "WIcon")
    return <icons.WIcon height={height} width={width} />;
  return null;
});
Icon.displayName = "Icon";
export default Icon;
