import { createSlice } from '@reduxjs/toolkit'


const initialState= {
  searchBarRegis: '',
  startSearchRegis:false,
  registeredUsers:[],
  registeredUserDetail:null
}

export const registeredSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setSearchBarRegis: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setStartSearchRegis: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setRegisteredUsers: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setRegisteredUserDetail: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  }
})

// Metodo global para actualizar el state
export const { setSearchBarRegis, setStartSearchRegis, setRegisteredUsers, setRegisteredUserDetail } = registeredSlice.actions

export default registeredSlice.reducer

