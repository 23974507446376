import { memo, useState } from "react";
import "./CInput.scss";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormMode } from "redux/features/newNaturalClient";

const CInput = memo(
  ({
    id,
    name,
    type,
    value,
    onChange,
    placeholder,
    label,
    disabled,
    formKey,
    arrayKey,
    rule,
    required,
    min,
    max,
    onKeyDown,
  }) => {
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const { mode } = useSelector((store) => store.newNaturalClient);
    /* useEffect(() => {
      const getError = () => {
       if(rule) setError(rule(value));
      };
      let button: HTMLButtonElement;
      document.querySelectorAll("button").forEach((occurence) => {
        //let id = occurence.getAttribute("id");
        button = occurence;
        button.addEventListener("click", getError);
      });
      return () => {
        button.removeEventListener("click", getError);
      };
    }, []); */
    const handleChange = (e) => {
      if (mode === 3) dispatch(setFormMode(2)); // se cambia a mode 2 porq se esta modificando el formulario que ya estaba lleno

      if (formKey) {
        onChange((prev) => {
          const inputFormObject = prev[formKey]; // se obtiene el objeto
          const inputOnArray = inputFormObject[arrayKey]; // se obtiene la info de los inputs segun su posicion
          const input = inputOnArray[e.target.name]; // la data del input que se modifica
          input.value = e.target.value; // se actualiza el value del input
          const newDataOnArray = { ...inputOnArray, [e.target.name]: input }; // se sustituye la informacion de los inputs por lo nuevo
          const newState = [...inputFormObject]; // se copia el array origianl
          newState[arrayKey] = newDataOnArray; // se cambia el objeto modificado dentro del array
          const updateStateData = { ...prev, [formKey]: newState }; // se actualiza el objeto del array, con el nuevo
          if (rule) setError(rule(e.target.value));
          return updateStateData;
        });
        return;
      }
      onChange((prev) => {
        const inputObject = prev[e.target.name];
        inputObject.value = e.target.value;
        const newData = { ...prev, [e.target.name]: inputObject };
        if (rule) setError(rule(e.target.value));
        return newData;
      });
    };
    const handleBlur = () => {
      if (rule) setError(rule(value));
    };

    return (
      <FormControl isRequired={required} isInvalid={error !== ""}>
        {label && (
          <FormLabel
            fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xs", xl: "xs" }}
            color="cBlueGray.800"
          >
            {label}
          </FormLabel>
        )}
        <Input

          className="input"
          fontWeight="400"
          bg="cBlueGray.50"

          variant='flushed'
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isInvalid={error !== ""}
          type={type}
          disabled={disabled}
          min={min}
          max={max}
          onBlur={handleBlur}
          onKeyDown={onKeyDown}
        />
        {error !== "" && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
);
CInput.displayName = "CInput";
export default CInput;
