import { AUTHSERVICE, AUTHSERVICE_FORMDATA, SELECT_SERVICES } from "../config";

export const postImageForm = async (type,body) => {
  const url = `/onboarding/${type}`;
  return await AUTHSERVICE_FORMDATA().post(url,body);
};

export const confirmOcrData = async (body) => {
  const url = `/onboarding/validationOcr`;
  return await AUTHSERVICE().post(url,body);
};

export const getCountryList = async () => {
  const url = "/list/country";
  return await SELECT_SERVICES().get(url);
};

export const getProvinceList = async (id) => {
  const url = `/list/province?idCountry=${id}`;
  return await SELECT_SERVICES().get(url);
};

export const getCitiesList = async (id) => {
  const url = `/list/cities?idProvince=${id}`;
  return await SELECT_SERVICES().get(url);
};

export const getCorregimientoList = async (
  id
) => {
  const url = `/list/corregimiento?idCity=${id}`;
  return await SELECT_SERVICES().get(url);
};
export const getDocTypeList = async () => {
  const url = "/list/documentType";
  return await SELECT_SERVICES().get(url);
};

export const getGenderList = async () => {
  const url = "/list/gender";
  return await SELECT_SERVICES().get(url);
};

export const getNationalityList = async () => {
  const url = "/list/nationality";
  return await SELECT_SERVICES().get(url);
};

export const getPepList = async () => {
  const url = "/list/pep";
  return await SELECT_SERVICES().get(url);
};

export const postAddressInformation = async (formData) => {
  const url = "/onboarding/address";
  return await AUTHSERVICE().post(url,formData);
};

export const postPepInformation = async (formData) => {
  const url = "/onboarding/pep";
  return await AUTHSERVICE().post(url,formData);
};

export const postNewClient = async (formData) => {
  const url = "/request/PersonalInfo";
  return await AUTHSERVICE().post(url,formData);
};

