/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import "./CRadioInput.scss";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { setFormMode } from "redux/features/newNaturalClient";
const CRadioInput = memo(
  ({ name, value, onChange, label, rule, radioOptions = [] }) => {
    const [error, setError] = useState("");
    const [RadioValue, setRadioValue] = React.useState(value);
    const dispatch = useDispatch();
    const { mode } = useSelector((store) => store.newNaturalClient);
    useEffect(() => {
      if (radioOptions.length > 0 && value !== "") setRadioValue(value);
    }, [radioOptions, value]);

    useEffect(() => {
      handleChange(RadioValue);
    }, [RadioValue]);

    const handleChange = (radioValue) => {
      if (mode === 3) dispatch(setFormMode(2)); // se cambia a mode 2 porq se esta modificando el formulario que ya estaba lleno

      onChange((prev) => {
        const inputObject = prev[name];
        inputObject.value = radioValue;
        const newData = { ...prev, [name]: inputObject };
        if (rule) setError(rule(radioValue));
        return newData;
      });
    };

    return (
      <FormControl>
        {label && (
          <FormLabel
            fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xs", xl: "xs" }}
            color="cBlueGray.800"
          >
            {label}
          </FormLabel>
        )}

        <RadioGroup
          onChange={setRadioValue}
          value={RadioValue}
          defaultValue={RadioValue}
        >
          <Stack direction="column">
            {radioOptions.map((res, key) => {
              return (
                <Radio 
                
                className="radio"
                key={`radio-option-${key}`} value={res.value}>
                  <div style={{textAlign: "start" , fontWeight: "500", fontSize: "14px"}}>

                  {res.label}
                  </div>
                </Radio>
              );
            })}
          </Stack>
        </RadioGroup>

        {error !== "" && <FormErrorMessage>{error}</FormErrorMessage>}

        {/* <Input
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isInvalid={error !== ""}
          type={type}
          disabled={disabled}
        /> */}
      </FormControl>
    );
  }
);
CRadioInput.displayName = "CRadioInput";
export default CRadioInput;
