export const DownChevron = ({ height = 7, width = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5775 0.744141L5 4.32164L1.4225 0.744141L0.244171 1.92247L5 6.67831L9.75584 1.92247L8.5775 0.744141Z"
        fill="currentColor"
      />
    </svg>
  );
};
