export const StepPassportIcon = ({ width = 20, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.78571 9.34375C6.02009 10.7476 6.92946 11.9101 8.16295 12.5054C7.82589 11.6527 7.60268 10.5566 7.53571 9.34375H5.78571ZM5.78571 7.90625H7.53259C7.59955 6.69336 7.82277 5.59816 8.16295 4.74465C6.93304 5.34121 6.02232 6.50469 5.78571 7.90625ZM10 12.8836C10.3482 12.5467 10.9286 11.3338 11.0446 9.34375H8.95089C9.07589 11.3338 9.65625 12.5467 10 12.8836ZM11.8348 4.74375C12.1786 5.59727 12.4018 6.69336 12.4688 7.90625H14.2156C13.9777 6.50469 13.0714 5.34121 11.8348 4.74375ZM17.1429 0H2.85714C1.27902 0 0 1.28701 0 2.875V20.125C0 21.713 1.27902 23 2.85714 23H17.1429C18.7143 23 20 21.7062 20 20.125V2.875C20 1.29375 18.7143 0 17.1429 0ZM15 18.6875H5C4.61161 18.6875 4.28571 18.3641 4.28571 17.9688C4.28571 17.5734 4.61161 17.25 5 17.25H15C15.3906 17.25 15.7143 17.5757 15.7143 17.9688C15.7143 18.3618 15.3929 18.6875 15 18.6875ZM10 14.375C6.84152 14.375 4.28571 11.8032 4.28571 8.625C4.28571 5.44678 6.84152 2.875 10 2.875C13.1585 2.875 15.7143 5.44678 15.7143 8.625C15.7143 11.8032 13.1607 14.375 10 14.375ZM11.8348 12.5062C13.0679 11.911 13.9777 10.7485 14.2121 9.34465H12.4652C12.4018 10.5566 12.1786 11.6527 11.8348 12.5062ZM8.95536 7.90625H11.0482C10.9241 5.9207 10.3482 4.70781 10 4.36865C9.65625 4.70781 9.07143 5.9207 8.95536 7.90625Z"
        fill="currentColor"
      />
    </svg>
  );
};
