export const CheckIcon = ({ height = 14, width = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="#03A9F4"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.59928 0.333344C3.91928 0.333344 0.932617 3.32001 0.932617 7.00001C0.932617 10.68 3.91928 13.6667 7.59928 13.6667C11.2793 13.6667 14.266 10.68 14.266 7.00001C14.266 3.32001 11.2793 0.333344 7.59928 0.333344ZM6.26595 10.3333L2.93262 7.00001L3.87262 6.06001L6.26595 8.44668L11.326 3.38668L12.266 4.33334L6.26595 10.3333Z"
        fill="#03A9F4"
      />
    </svg>
  );
};
