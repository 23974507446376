import React, { memo } from "react";
import "./OcrStep.scss";
import { Button, Heading, IconButton, Text } from "@chakra-ui/react";
import { Card, Col, Divider, Row } from "antd";
import Navbar from "components/Navbar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { confirmOcrData } from "service/newNaturalClient/formOne";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import { useNavigate } from "react-router-dom";


const repiteImage = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.083252 13.6654V7.83206H5.91658L3.23575 10.5154C3.69365 10.9836 4.24023 11.356 4.84359 11.6106C5.44696 11.8653 6.09501 11.9972 6.74992 11.9987C7.78263 11.9972 8.78954 11.6759 9.6323 11.0791C10.4751 10.4822 11.1123 9.63905 11.4566 8.6654H11.4716C11.5666 8.39456 11.6391 8.1154 11.6874 7.83206H13.3641C13.1611 9.44308 12.3772 10.9246 11.1594 11.9987C9.94158 13.0727 8.37367 13.6653 6.74992 13.6654H6.74158C5.86643 13.668 4.99945 13.497 4.19083 13.1623C3.38221 12.8276 2.64802 12.3358 2.03075 11.7154L0.083252 13.6654ZM1.81159 6.1654H0.134918C0.337815 4.55495 1.12129 3.07386 2.33838 1.9999C3.55548 0.92595 5.12258 0.332928 6.74575 0.332064H6.74992C7.62522 0.329293 8.49237 0.50022 9.30115 0.834944C10.1099 1.16967 10.8442 1.66154 11.4616 2.28206L13.4166 0.332064V6.1654H7.58325L10.2683 3.48206C9.8099 3.01329 9.26264 2.64065 8.65852 2.38596C8.05439 2.13126 7.40554 1.99961 6.74992 1.99873C5.71721 2.00026 4.7103 2.32152 3.86753 2.91838C3.02477 3.51524 2.38749 4.35841 2.04325 5.33206H2.02825C1.93242 5.6029 1.85992 5.88206 1.81242 6.1654H1.81159Z"
        fill="#2A94D0"
      />
    </svg>
  );
};




const OcrStep = memo(() => {
  const { trackData } = useSelector((store) => store.newNaturalClient);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRepit = React.useCallback(() => {
    sessionStorage.removeItem("img-preview");
    handleCheckOCRData(false)
    dispatch(setNewNaturalStep(3))
  }, [navigate]);

  const handleCheckOCRData = async (validation) => {
    const body = { validation };
    try {
      const res = await confirmOcrData(body);
      if (res.data.message === "Datos marcados como incorrectos") {
        dispatch(setFormTrackInfo({ ...res.data, bad_data: true }));
     
        
        return;
      }
      dispatch(setFormTrackInfo(res.data));
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
    } catch (error) {
      ErrorCToast(error);
    }
  };

  return (
    <div className="ocr-step">
      <Navbar title="Confirmar datos" />
      <Heading
        className="title-data"
      >
        ¿Confirma que son correctos los <br></br>datos de su documento de <br></br> identidad?
      </Heading>
      <Row >
        <Col span={24} className="col">
          <Row justify="space-between" style={{ paddingBottom: "10px", paddingTop: "10px" }} >
            <Col span={12}>
              <Text
                className="label"
              >
                ID Documento
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.idDocument}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                Expedida
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.expeditionDate}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                Expira
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.expirationDate}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                Nombres
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.names}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                Apellidos
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.lastNames}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                F. Nacimiento
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.birthday}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                Nacionalidad
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.nationality}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                País de Nac.
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.placeOfBirth}</Text></Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="col">
          <Row justify="space-between" style={{  paddingBottom: "16px" , paddingTop: "16px"}}>
            <Col span={12} >
              <Text
                className="label"
              >
                Sexo
              </Text>
            </Col>
            <Col span={12}>
              <Row justify={"end"}><Text className="datos">{trackData.data.gender}</Text></Row>
            </Col>
          </Row>
        </Col>


        {/* 
          <Col span={24}>
            <Row justify="center">
              <Heading
                as="h5"
                fontSize={{
                  base: "sm",
                  sm: "sm",
                  md: "sm",
                  lg: "sm",
                  xl: "sm",
                }}
                style={{ margin: "0" }}
                onClick={() => handleCheckOCRData(false)}
                 onClick={() => dispatch(setNewNaturalStep(3))} 
              >
                ¿Hay algo mal con tus datos?
                <span className="link"> Click aquí</span>
              </Heading>
            </Row>
          </Col> */}
      </Row>

      <div className="footer-doc background">
        <div onClick={() => handleRepit()}>
          <IconButton
            bg="UniOrange.100"
            color="UniOrange.600"
            aria-label="Call Segun"
            size="lg"
            icon={repiteImage()}
            className="circle-doc"

          />
        </div>
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.600"
          colorScheme="UniOrange"
          color="UniOrange.50"
          onClick={() => handleCheckOCRData(true)}

          id="submit-button"
          className="si-correcto"
        /*      isDisabled={!allInputFilled({...personalValues,...homeAdress,...kycClient})} */
        >
          Sí, son correctos
        </Button>
      </div>
    </div>
  );
});
OcrStep.displayName = "OcrStep";
export default OcrStep;
