import { useEffect } from "react";
import { handleErrors } from "helpers/handleErrors";
import { getPepList } from "service/newNaturalClient/formOne";

const pepOptions = (docTypes) => {
  const cleanDocs = docTypes.map((res) => {
    return { label: res.name, value: res.idPep.toString() };
  });
  return cleanDocs;
};

export const useKycClient = ({ setKycClient }) => {
  const handleGetInitialState = async () => {
    try {
      const result = await getPepList();
      setKycClient((prev) => {
        const { pep } = prev;
        return {
          ...prev,
          pep: { ...pep, options: pepOptions(result.data) },
        };
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  useEffect(() => {
    handleGetInitialState();
  }, []);
};
