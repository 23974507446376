import { AUTHSERVICE, SELECT_SERVICES } from "../config";

export const getPepRelatedList = async () => {
  const url = "/list/relationshipPep";
  return await SELECT_SERVICES().get(url);
};


export const postStepThreeData = async (formData) => {
  const url = "/request/RelationshipPepInfo";
  return await AUTHSERVICE().post(url,formData);
};

