export const ReloadIcon = ({ height = 15, width = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2041 2.29183C10.9957 1.0835 9.3374 0.333496 7.49574 0.333496C3.8124 0.333496 0.837402 3.31683 0.837402 7.00016C0.837402 10.6835 3.8124 13.6668 7.49574 13.6668C10.6041 13.6668 13.1957 11.5418 13.9374 8.66683H12.2041C11.5207 10.6085 9.67074 12.0002 7.49574 12.0002C4.7374 12.0002 2.49574 9.7585 2.49574 7.00016C2.49574 4.24183 4.7374 2.00016 7.49574 2.00016C8.87907 2.00016 10.1124 2.57516 11.0124 3.4835L8.32907 6.16683H14.1624V0.333496L12.2041 2.29183Z"
        fill="currentColor"
      />
    </svg>
  );
};
