import { memo } from "react";
import { Heading } from "@chakra-ui/react";
import { Card } from "antd";
import CSelect from "components/inputs/Select/CSelect";
import CInput from "components/inputs/Input/CInput";

const RelatedPepForm = memo(({ relatedPepValues, setrelatedPepValues }) => {
  return (
    <form className="form">
      <Card
        title={
          <Heading
            as="h4"
            fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
            color={"cBlueGray.700"}
            style={{ margin: "0", padding: "0 16px" }}
          >
            Relación con alguien cercano PEP
          </Heading>
        }
      >
        <div className="row wrap">
          <div className="col">
            <CSelect
              label={relatedPepValues.relationship.label}
              name={relatedPepValues.relationship.name}
              value={relatedPepValues.relationship.value}
              onChange={setrelatedPepValues}
              placeholder={relatedPepValues.relationship.placeholder}
              error={relatedPepValues.relationship.error}
              options={relatedPepValues.relationship.options}
              rule={relatedPepValues.relationship.rule}
            />
          </div>
          <div className="col">
            <CInput
              label={relatedPepValues.pepPerson.label}
              name={relatedPepValues.pepPerson.name}
              value={relatedPepValues.pepPerson.value}
              onChange={setrelatedPepValues}
              placeholder={relatedPepValues.pepPerson.placeholder}
              error={relatedPepValues.pepPerson.error}
              type={relatedPepValues.pepPerson.type}
              rule={relatedPepValues.pepPerson.rule}
            />
          </div>
          <div className="col">
            <CInput
              label={relatedPepValues.positionPep.label}
              name={relatedPepValues.positionPep.name}
              value={relatedPepValues.positionPep.value}
              onChange={setrelatedPepValues}
              placeholder={relatedPepValues.positionPep.placeholder}
              error={relatedPepValues.positionPep.error}
              type={relatedPepValues.positionPep.type}
              rule={relatedPepValues.positionPep.rule}
            />
          </div>
        </div>
      </Card>
    </form>
  );
});
RelatedPepForm.displayName = "RelatedPepForm";
export default RelatedPepForm;
