import { createSlice } from "@reduxjs/toolkit";

export const trackDataInitialState= {
  requestNumber: "",
  track: "",
  data: {},
  nextView: 1,
};


const initialState = {
  changeView: undefined,
  step: 2, //2 vista de selfie como primera vista
  requestNumber: "",
  trackData: trackDataInitialState, // neutral state
  loadingTrackData: false,
  mode: 1,
  // si no hay data en la track data mode se va a 1
  // si hay data en el track data y se cambia algo en el formulario mode se va a 2
  // si hay data en la trackData mode se va a 3,
};

export const registeredSlice = createSlice({
  name: "newNaturalClient",
  initialState,
  reducers: {
    setNewNaturalStep: (state, action) => {
      return {
        ...state,
        step: action.payload,
      };
    },
    setFormTrackInfo: (state, action) => {
      return {
        ...state,
        trackData: action.payload,
      };
    },
    setLoadingTrack: (state, action) => {
      return {
        ...state,
        loadingTrackData: action.payload,
      };
    },
    setFormMode: (state, action) => {
      return {
        ...state,
        mode: action.payload,
      };
    },
    setNewView: (state, action) => {
      return {
        ...state,
        changeView: action.payload,
      };
    },
  },
});

// Metodo global para actualizar el state
export const {
  setNewNaturalStep,
  setFormTrackInfo,
  setLoadingTrack,
  setFormMode,
  setNewView,
} = registeredSlice.actions;

export default registeredSlice.reducer;
