export const StepLaboralDataIcon = ({ width = 24, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6 4.8H16.8V2.4C16.8 1.068 15.732 0 14.4 0H9.6C8.268 0 7.2 1.068 7.2 2.4V4.8H2.4C1.068 4.8 0.012 5.868 0.012 7.2L0 20.4C0 21.732 1.068 22.8 2.4 22.8H21.6C22.932 22.8 24 21.732 24 20.4V7.2C24 5.868 22.932 4.8 21.6 4.8ZM14.4 4.8H9.6V2.4H14.4V4.8Z"
        fill="currentColor"
      />
    </svg>
  );
};
