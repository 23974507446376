import { configureStore } from "@reduxjs/toolkit";
import requestReducer from "./features/request";
import registeredReducer from "./features/registered";
import tabsReducer from "./features/tabs";
import camReducer from './features/cam';
import newNaturalClientReducer from "./features/newNaturalClient";

export default configureStore({
  reducer: {
    request: requestReducer,
    registered: registeredReducer,
    tabs: tabsReducer,
    newNaturalClient: newNaturalClientReducer,
    camera: camReducer,
  },
});
