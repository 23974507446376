export const PassIconCheck = ({ height = 20, width = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00002 0.666504C4.40002 0.666504 0.666687 4.39984 0.666687 8.99984C0.666687 13.5998 4.40002 17.3332 9.00002 17.3332C13.6 17.3332 17.3334 13.5998 17.3334 8.99984C17.3334 4.39984 13.6 0.666504 9.00002 0.666504ZM9.00002 15.6665C5.32502 15.6665 2.33335 12.6748 2.33335 8.99984C2.33335 5.32484 5.32502 2.33317 9.00002 2.33317C12.675 2.33317 15.6667 5.32484 15.6667 8.99984C15.6667 12.6748 12.675 15.6665 9.00002 15.6665ZM12.825 5.3165L7.33335 10.8082L5.17502 8.65817L4.00002 9.83317L7.33335 13.1665L14 6.49984L12.825 5.3165Z"
        fill="#009688"
      />
    </svg>
  );
};
