export const SolicitudesIcon = ({ height = 18, width = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9501 14.2748C16.1251 12.8332 16.8334 10.9998 16.8334 8.99984C16.8334 4.39984 13.1001 0.666504 8.50008 0.666504C3.90008 0.666504 0.166748 4.39984 0.166748 8.99984C0.166748 13.5998 3.90008 17.3332 8.50008 17.3332C10.5001 17.3332 12.3334 16.6248 13.7751 15.4498C14.0001 15.2665 14.2167 15.0665 14.4251 14.8582C14.4501 14.8332 14.4667 14.8082 14.4834 14.7915C14.6501 14.6248 14.8084 14.4498 14.9501 14.2748ZM8.50008 15.6665C4.82508 15.6665 1.83341 12.6748 1.83341 8.99984C1.83341 5.32484 4.82508 2.33317 8.50008 2.33317C12.1751 2.33317 15.1667 5.32484 15.1667 8.99984C15.1667 10.5415 14.6417 11.9498 13.7584 13.0832L12.5667 11.8915C13.1417 11.0748 13.4834 10.0832 13.4834 9.00817C13.4834 6.24984 11.2417 4.00817 8.48341 4.00817C5.72508 4.00817 3.48341 6.24984 3.48341 9.00817C3.48341 11.7665 5.72508 14.0082 8.48341 14.0082C9.56675 14.0082 10.5751 13.6582 11.3917 13.0665L12.5751 14.2498C11.4501 15.1415 10.0417 15.6665 8.50008 15.6665ZM10.1001 9.42484C10.2417 8.87484 10.1167 8.27484 9.69175 7.8415L9.67508 7.82484C9.03341 7.18317 8.00842 7.17484 7.35842 7.7915C7.35008 7.79984 7.33342 7.80817 7.31675 7.82484C6.66675 8.47484 6.66675 9.53317 7.31675 10.1832L7.33342 10.1998C7.76675 10.6248 8.37508 10.7582 8.92508 10.6082L10.1834 11.8665C9.68342 12.1665 9.10841 12.3498 8.48341 12.3498C6.64175 12.3498 5.15008 10.8582 5.15008 9.0165C5.15008 7.17484 6.64175 5.68317 8.48341 5.68317C10.3251 5.68317 11.8167 7.17484 11.8167 9.0165C11.8167 9.62484 11.6417 10.1915 11.3501 10.6832L10.1001 9.42484Z"
        fill="currentColor"
      />
    </svg>
  );
};
