import React, { useState, memo } from "react";
import "./DocIdStep.scss";
import Footer from "templates/FormTemplate/components/Footer/Footer";
import Navbar from "components/Navbar/Navbar";
import { postImageForm } from "service/newNaturalClient/formOne";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { useDispatch } from "react-redux";
import ErrorCToast from "components/ErrorToast/ErrorCToast";
import IconId from "../../../../assets/icons/id-scan.svg";

const DocIdStep = memo(() => {
  const [Picture, setPicture] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [firstScreen, setFirstScreen] = useState("SECOND");
  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {

        setPicture(reader.result);

        setFirstScreen("THREE");

      };
      reader.readAsDataURL(file);
    }
  };

  const handleSentPhoto = async () => {
    setLoading(true);
    const body = {
      requestNumber: sessionStorage.getItem("requestNumber"),
      image: Picture,
    };
    const formData = new FormData();
    Object.entries(body).forEach(([key, value]) => {
      formData.append(key, value);
    });
    try {
      const res = await postImageForm("document", formData);
      dispatch(setNewNaturalStep(parseInt(res?.data?.nextView)));
      dispatch(setFormTrackInfo(res?.data));
      localStorage.setItem("User:",res?.data?.data.names)
      console.log("NOMBRE:", res?.data?.data.names)

    } catch (error) {
      // console.log("DOCUMENTO:", error);
      ErrorCToast(error);
      setFirstScreen("SECOND"); // Regresar a la pantalla "SECOND"
    } finally {
      setLoading(false);
    }
  };

  const handleRepit = () => {
    setFirstScreen("SECOND");
    //setPicture(undefined);
  };

  return (
    <div className="vista">
      {firstScreen === "SECOND" && (
        <>
          <Navbar title="Captura de cédula" noBackbutton={true} />
          <div className="container-doc">
            <img id="face-logo" src={IconId} alt="Icon" />
            <p className="text-doc">
        Prepárese para escanear 
              <br />
              su cédula de Panamá o Pasaporte
            </p>
          </div>
          <div className="camera-container">
            <input
              type="file"
              accept="image/*"
              capture="environment" // Esto abre la cámara nativa
              onChange={handleImageChange}
              style={{ display: "none" }} 
              id="camera-input"
            />
            <button
              className="big-button"
              onClick={() => document.getElementById('camera-input').click()}
            >
              Estoy listo
            </button>
          </div>
          {/* <Footer onClick={() => document.getElementById('camera-input').click()} camera /> */}
        </>
      )}
      {firstScreen === "THREE" && (
        <>
          <Navbar title="Captura de cédula" noHomebutton={true} />
          <div className="container-preview">
            <img src={Picture} alt="preview" className="image-preview" />
          </div>
          <Footer
            onClick={handleSentPhoto}
            loading={loading}
            preview
            handleRepit={handleRepit}
          />
        </>
      )}
      {firstScreen === "FIRST" && (
        <div>
          <Navbar title="Cédula" noBackbutton={true} />
          <div style={{ height: "250px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            <img id="face-logo" src={IconId} alt="Icon" />
            <p style={{ marginTop: "16px" }}>
              Prepárese para escanear su
              <br />
              Cédula de Panamá
            </p>
          </div>
          <button className="big-button" onClick={() => document.getElementById('camera-input').click()}>
            Continuar
          </button>
        </div>
      )}
    </div>
  );
});

DocIdStep.displayName = "DocIdStep";
export default DocIdStep;