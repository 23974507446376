import React, { useEffect } from "react";
import Router from "./Routes/Routes";





const App = () => {
  useEffect(() => {
    /*  const contenedor = contenedorRef.current; */
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  return (
      <Router />
  )
};
export default App;
