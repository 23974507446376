import { useEffect } from "react";
import { handleErrors } from "helpers/handleErrors";
import {
  getCitiesList,
  getCorregimientoList,
  getCountryList,
  getProvinceList,
} from "service/newNaturalClient/formOne";

export const countryOptions = (countries) => {
  const cleanCountries = countries.map((res) => {
    return { label: res.country, value: res.id };
  });
  return cleanCountries;
};

export const oneCountry = (countries) => {
  const cleanCountries = [
    { label: countries[0].country, value: countries[0].id },
  ]; // se coloco de esta forma porque de momento solo se puede usar panama, los demas paises dan error
  /* const cleanCountries = countries.map((res,key) => {
    return { label: res.country, value: res.id };
  }); */
  return cleanCountries;
};

const provinceOptions = (provinces) => {
  const cleanProvinces = provinces.map((res) => {
    return { label: res.nameProvince, value: res.idProvince };
  });
  return cleanProvinces;
};

const citiesOptions = (cities) => {
  const cleanProvinces = cities.map((res) => {
    return { label: res.nameCity, value: res.idCity };
  });
  return cleanProvinces;
};

const corregimientoOptions = (cities) => {
  const cleanProvinces = cities.map((res) => {
    return { label: res.nameCorregimiento, value: res.idCorregimiento };
  });
  return cleanProvinces;
};

export const useHomeAdress = ({ homeAdress, setHomeAdress }) => {
  const handleGetInitialState = async (countryId) => {
    let result, result2;
    try {
      const venOrPan = (id = "1") => {
        if (id === "1" || id === "192") return true;
        return false;
      };
      result = await getCountryList();
      if (venOrPan(countryId)) {
        result2 = await getProvinceList(countryId);
      }
      setHomeAdress((prev) => {
        const { countryResident, cityResident, provinceResident, district } =
          prev;
        return {
          ...prev,
          countryResident: {
            ...countryResident,
            options: countryOptions(result.data),
          },
          provinceResident: {
            ...provinceResident,
            label: "Provincia",
            placeholder:"Seleccione",
            options: venOrPan(countryId) ? provinceOptions(result2.data) : [],
            required: venOrPan(countryId),
            disabled: !venOrPan(countryId),
            value: venOrPan(countryId) ? provinceResident.value : "",
          },
          district: {
            ...district,
            label: "Corregimiento",
            placeholder: "Seleccione",
            // placeholder: countryId === "1" ? "Corregimiento" : "Municipio",
            options: venOrPan(countryId) ? district.options : [],
            required: venOrPan(countryId),
            value: venOrPan(countryId) ? district.value : "",
            disabled: !venOrPan(countryId),
          },
          cityResident: {
            ...cityResident,
            label: "Ciudad",
            placeholder: "Seleccione",
            options: venOrPan(countryId) ? cityResident.options : [],
            required: venOrPan(countryId),
            disabled: !venOrPan(countryId),
            value: venOrPan(countryId) ? cityResident.value : "",
          },
        };
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleGetCities = async (provinceId) => {
    try {
      const result = await getCitiesList(provinceId);
      setHomeAdress((prev) => {
        const { cityResident } = prev;
        return {
          ...prev,
          cityResident: {
            ...cityResident,
            options: citiesOptions(result.data),
          },
        };
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleGetCorregimiento = async (cityId) => {
    try {
      const result = await getCorregimientoList(cityId);
      setHomeAdress((prev) => {
        const { district } = prev;
        return {
          ...prev,
          district: { ...district, options: corregimientoOptions(result.data) },
        };
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  useEffect(() => {
    handleGetInitialState(homeAdress.countryResident.value);
  }, [homeAdress.countryResident.value]);

  useEffect(() => {
    if (homeAdress.provinceResident.value !== "")
      handleGetCities(homeAdress.provinceResident.value);
  }, [homeAdress.provinceResident.value]);

  useEffect(() => {
    if (homeAdress.cityResident.value !== "")
      handleGetCorregimiento(homeAdress.cityResident.value);
  }, [homeAdress.cityResident.value]);
  return {
    /*  homeAdress,
    setHomeAdress, */
  };
};
