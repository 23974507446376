export const TinCheck = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
  <path d="M13.3327 4L5.99935 11.3333L2.66602 8" stroke="#56AD70" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

