export const VitalSignIcon = ({ height = 14, width = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.6667 0.333496H2.33341C1.41675 0.333496 0.666748 1.0835 0.666748 2.00016V4.50016H2.33341V2.00016H15.6667V4.50016H17.3334V2.00016C17.3334 1.0835 16.5834 0.333496 15.6667 0.333496Z"
        fill="currentColor"
      />
      <path
        d="M15.6667 12.0002H2.33341V9.50016H0.666748V12.0002C0.666748 12.9168 1.41675 13.6668 2.33341 13.6668H15.6667C16.5834 13.6668 17.3334 12.9168 17.3334 12.0002V9.50016H15.6667V12.0002Z"
        fill="currentColor"
      />
      <path
        d="M11.4084 3.29183C11.1251 2.72516 10.2001 2.72516 9.91675 3.29183L7.33342 8.46683L6.40842 6.62516C6.26675 6.34183 5.98341 6.16683 5.66675 6.16683H0.666748V7.8335H5.15008L6.58342 10.7085C6.73342 10.9918 7.01675 11.1668 7.33342 11.1668C7.65008 11.1668 7.93342 10.9918 8.07508 10.7085L10.6667 5.5335L11.5917 7.37516C11.7334 7.6585 12.0167 7.8335 12.3334 7.8335H17.3334V6.16683H12.8501L11.4084 3.29183Z"
        fill="currentColor"
      />
    </svg>
  );
};
