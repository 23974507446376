export const InfoI = ({ height = 20, width = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.66667 0.833496C4.60417 0.833496 0.5 4.93766 0.5 10.0002C0.5 15.0627 4.60417 19.1668 9.66667 19.1668C14.7292 19.1668 18.8333 15.0627 18.8333 10.0002C18.8333 4.93766 14.7292 0.833496 9.66667 0.833496ZM9.25 5.00016C9.02899 5.00016 8.81702 5.08796 8.66074 5.24424C8.50446 5.40052 8.41667 5.61248 8.41667 5.8335C8.41667 6.05451 8.50446 6.26647 8.66074 6.42275C8.81702 6.57903 9.02899 6.66683 9.25 6.66683H9.66667C9.88768 6.66683 10.0996 6.57903 10.2559 6.42275C10.4122 6.26647 10.5 6.05451 10.5 5.8335C10.5 5.61248 10.4122 5.40052 10.2559 5.24424C10.0996 5.08796 9.88768 5.00016 9.66667 5.00016H9.25ZM8 8.3335C7.77899 8.3335 7.56702 8.42129 7.41074 8.57757C7.25446 8.73385 7.16667 8.94582 7.16667 9.16683C7.16667 9.38784 7.25446 9.5998 7.41074 9.75608C7.56702 9.91236 7.77899 10.0002 8 10.0002H8.83333V12.5002H8C7.77899 12.5002 7.56702 12.588 7.41074 12.7442C7.25446 12.9005 7.16667 13.1125 7.16667 13.3335C7.16667 13.5545 7.25446 13.7665 7.41074 13.9228C7.56702 14.079 7.77899 14.1668 8 14.1668H11.3333C11.5543 14.1668 11.7663 14.079 11.9226 13.9228C12.0789 13.7665 12.1667 13.5545 12.1667 13.3335C12.1667 13.1125 12.0789 12.9005 11.9226 12.7442C11.7663 12.588 11.5543 12.5002 11.3333 12.5002H10.5V9.16683C10.5 8.94582 10.4122 8.73385 10.2559 8.57757C10.0996 8.42129 9.88768 8.3335 9.66667 8.3335H8Z"
        fill="currentColor"
      />
    </svg>
  );
};
