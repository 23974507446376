/*Por lo general la función handleChange está diseñada para actualizar el estado de un objeto en respuesta a un evento,
 típicamente un evento de cambio en un campo de formulario*/

export const handleChange = (
  e,
  setValues
) => {
  setValues((prev) => {
    return { ...prev, [e.target.name]: e.target.value };
  });
};
