export const UploadIcon = ({ height = 12, width = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18392 0.0615234H1.51725C0.875586 0.0615234 0.350586 0.586523 0.350586 1.22819V10.5615C0.350586 11.2032 0.869752 11.7282 1.51142 11.7282H6.76725V7.06152H9.68392V3.56152L6.18392 0.0615234ZM5.60059 4.14486V0.936523L8.80892 4.14486H5.60059ZM7.93392 11.5299V8.22819H11.2356V9.39486H9.92892L11.6498 11.1157L10.8273 11.9382L9.10059 10.2174V11.524H7.93392V11.5299Z"
        fill="currentColor"
      />
    </svg>
  );
};
