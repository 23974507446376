export const HouseIcon = ({ height = 16, width = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33342 15.0832V10.0832H10.6667V15.0832H14.8334V8.4165H17.3334L9.00008 0.916504L0.666748 8.4165H3.16675V15.0832H7.33342Z"
        fill="currentColor"
      />
    </svg>
  );
};
