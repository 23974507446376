
export const StepsFaceIcon = ({ width = 36, height = 36 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z" fill="currentColor" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9702 27.2695C21.1391 27.6334 20.9811 28.0654 20.6173 28.2343C18.9356 29.0152 17.2696 29.1585 15.9046 28.532C14.52 27.8964 13.6173 26.5487 13.3208 24.7201L14.7548 24.4875C14.9944 25.9649 15.6712 26.8262 16.5107 27.2116C17.3697 27.6059 18.5724 27.582 20.0054 26.9166C20.3693 26.7477 20.8012 26.9057 20.9702 27.2695Z" fill="#FFFFFF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9906 19.9811V17.0094H20.3113V21.3019H16.6792V19.9811H18.9906Z" fill="#FFFFFF" />
      <path d="M24.7455 13.7077C24.7455 14.4371 24.1542 15.0284 23.4248 15.0284C22.6953 15.0284 22.104 14.4371 22.104 13.7077C22.104 12.9782 22.6953 12.3869 23.4248 12.3869C24.1542 12.3869 24.7455 12.9782 24.7455 13.7077Z" fill="#FFFFFF" />
      <path d="M13.8965 13.7077C13.8965 14.4371 13.3052 15.0284 12.5758 15.0284C11.8463 15.0284 11.255 14.4371 11.255 13.7077C11.255 12.9782 11.8463 12.3869 12.5758 12.3869C13.3052 12.3869 13.8965 12.9782 13.8965 13.7077Z" fill="#FFFFFF" />
    </svg>
  );
};

