
/* Esta función verifica si todos los campos en el objeto form están completos y cumplen con sus reglas, si las tienen*/
export const allInputFilled = (form) => {
  let allComplete = true;
  const entries = Object.entries(form);
  for (let x = 0; x < entries.length; x++) {
    const [, input] = entries[x];
    if (input.rule && input.rule(input.value)) {
      const error = input.rule(input.value);
      if (error !== "") {
        allComplete = false;
        break;
      }
    }
    if (input.value === "" && !input.rule) {
      allComplete = false;
      break;
    }
  }
  return allComplete;
};
/*Esta función es similar a allInputFilled, pero además verifica si los campos son requeridos (input.required).*/
export const allInputFilledWithRequired = (form) => {
  let allComplete = true;
  const entries = Object.entries(form);
  for (let x = 0; x < entries.length; x++) {
    const [, input] = entries[x];
    if (input.rule && input.rule(input.value) && input.required) {
      const error = input.rule(input.value);
      if (error !== "") {
        allComplete = false;
        break;
      }
    }
    if (input.value === "" && !input.rule && input.required) {
      allComplete = false;
      break;
    }
  }
  return allComplete;
};
/*Esta función verifica si todos los campos en cada objeto dentro de un array de formularios están completos y cumplen con sus reglas, si las tienen.*/
export const allInputFilledArray = (form) => {
  let allComplete = true;
  for (let x = 0; x < form.length; x++) {
    const entries = Object.entries(form[x]);
    for (let x = 0; x < entries.length; x++) {
      const [, input] = entries[x];
      if (input.rule && input.rule(input.value)) {
        const error = input.rule(input.value);
        if (error !== "") {
          allComplete = false;
          break;
        }
      }
      if (input.value === "") {
        allComplete = false;
        break;
      }
    }
  }

  return allComplete;
};
