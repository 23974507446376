/*Esta función toma un objeto de entrada (objectInput), que se espera que contenga
varios objetos, cada uno representando un campo de un
formulario con una propiedad value.*/

export const getAllValuesFromForm = (objectInput) => {
  //Inicializa un objeto vacío, Este objeto almacenará los pares clave-valor, donde la clave es el nombre del campo y el valor es el valor del campo.
  const allValues = {};
  //Itera sobre las entradas del objeto de entrada, Utiliza Object.entries para obtener un array de pares [key, value]. Luego, itera sobre estos pares y asigna el valor de object.value a allValues bajo la clave key
  Object.entries(objectInput).forEach(([key, object]) => {
    allValues[key] = object.value;
  });
  //Devuelve el objeto con todos los valores extraídos
  return allValues;
};

//Esta función está diseñada para trabajar con un array de objetos de entrada. 
//Cada objeto en el array representa un formulario,
//y la función extrae los valores de cada uno y los almacena en un array.
export const getAllValuesFromArrayForm = (objectInput) => {
  //Inicializa un array vacío, Este array almacenará los objetos con los valores extraídos de cada formulario.
  const allValues = [];
  //Itera sobre el array de objetos de entrada
  objectInput.forEach((res) => {
    const values = {};
    Object.entries(res).forEach(([key, object]) => {
      values[key] = object.value;
    });
    allValues.push(values);
  });
//Devuelve el array con todos los valores extraídos
  return allValues;
};
