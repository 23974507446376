export const DiagonalUpIcon = ({ height = 14, width = 14 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.91667 0.75V2.41667H10.4083L0.75 12.075L1.925 13.25L11.5833 3.59167V9.08333H13.25V0.75H4.91667Z"
        fill="currentColor"
      />
    </svg>
  );
};
