export const PencilIcon = ({ height = 16, width = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.499023 12.376V15.501H3.62402L12.8407 6.28432L9.71569 3.15932L0.499023 12.376ZM15.2574 3.86765C15.5824 3.54265 15.5824 3.01765 15.2574 2.69265L13.3074 0.742651C12.9824 0.417651 12.4574 0.417651 12.1324 0.742651L10.6074 2.26765L13.7324 5.39265L15.2574 3.86765Z"
        fill="currentColor"
      />
    </svg>
  );
};
