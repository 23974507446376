import { memo } from "react";
import WelcomeTemplate from "templates/WelcomeTemplate/WelcomeTemplate";
import WelcomeTh from "../../../assets/icons/welcome-three.svg"
import PointWTh from "../../../assets/icons/point-welTh.svg"
import { Text } from "@chakra-ui/layout";
import { useNavigate } from "react-router";
import "./Welcome.scss"
const WelcomeThree = memo(() => {

    const navigate = useNavigate();
    const requestId = sessionStorage.getItem("requestID:");

    //CUANDO SE VAYA A CAMBIAR EL TYPE SE HACE LO MISMO QUE CON REQUESTID
    const handleClick = () => {
        return navigate(`/mode/BANA/${requestId}`)
    }
    const handleClickOne = () => {
        return navigate("/welcome-two")
    }
    return (
        <WelcomeTemplate onClick={handleClick} showIcon={true} onClickOne={handleClickOne}>
            <img src={WelcomeTh} alt="welcome-one" />
            <img src={PointWTh} alt="welcome-one" />
            <Text color="pinkBen.500" fontSize="16" fontWeight="600">Disfruta tu tiempo libre!</Text>
            <Text color="cBlueGray.700" fontSize="14" fontWeight="500">Envía y recibe dinero desde cualquier lugar de Panamá</Text>
        </WelcomeTemplate>
    );
});
WelcomeThree.displayName = "WelcomeThree";
export default WelcomeThree;