import { memo } from "react";
import Logo from "../../assets/logos/logoWhiteBnp.svg";
import "./WelcomeTemplate.scss";
import { Button, Heading, Icon, Text } from "@chakra-ui/react";
import { WIcon } from "components/icons/svg";
import { useNavigate } from "react-router-dom";

const WelcomeTemplate = memo(({ children, title, subtitle, showIcon, bodyBorderless, onClick, onClickOne }) => {
  return (
    <>
      <div className="welcome-template">
        <div className="logo-container">
          <img src={Logo} alt="bnp-logo" />
        </div>
        {(title || subtitle) && (
          <div className="title">
            {title && (
              <Heading color="cBlueGray.800" as="h3" size="md" style={{
                fontFamily: "poppins, saint-serif",
                paddingBottom: "8px"
              }}>
                {title}
              </Heading>
            )}
            {subtitle && (
              <Text className="subtitle" color="cBlueGray.800">
                {subtitle}
              </Text>
            )}
          </div>
        )}

        <div className="body-template" data-variation={bodyBorderless ? "borderless" : ""}>
          {children}
        </div>
      </div>
      <div className="footer-welcome">
        {showIcon && (
          <Button
            as="button"
            height="48px"
            className="button-back"
            onClick={onClickOne}
           
            border="2px solid #F1CCE6"
            
            _hover={{ bg: "transparent", color: "transparent" }}
          >
            <Icon as={WIcon} className="icon-back"/>
          </Button>
        )}

        <Button
         
         size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "md", sm: "md", md: "md", lg: "md", xl: "md" }}
          fontWeight="600"
          bg="UniOrange.500"
          colorScheme="UniOrange"
          color="UniOrange.50"
          width="100%"
          onClick={onClick}
          borderRadius={16}
        >
          Siguiente
        </Button>
      </div>
    </>
  );
});

WelcomeTemplate.displayName = "WelcomeTemplate";
export default WelcomeTemplate;