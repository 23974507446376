import React ,{ memo, Suspense, lazy, useState } from "react";
import { Button, Checkbox, Link, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, background } from "@chakra-ui/react";
import "./CompanySelectorForm.scss";
import { useCompanySelector } from "../../hooks/useCompanySelector";
import { Card, Col, Row } from "antd";
import { useLocation } from "react-router-dom";
import { allInputFilled } from "helpers/validateAllInputsFilled";
import Navbar from "components/Navbar/Navbar";
import CInput from "components/inputs/Input/CInput";
import CSelect from "components/inputs/Select/CSelect";

const MyLazyComponent = lazy(() => import("components/WhiteScreenLoader/WhiteScreenLoader"));

const CompanySelectorForm = memo(() => {
  const { values, handleSubmitForm, loading, products, setValues } = useCompanySelector();
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [size, setSize] = React.useState("sm");





  const handleOpenModal = () => {
    onOpen();
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div className="initial-company-form">
      {location.state !== null && <Navbar title="Editar" />}
      <Suspense fallback={null}>{loading && <MyLazyComponent />}</Suspense>
      <form className="form" onSubmit={handleSubmitForm}>
      <Row gutter={[16, 16]}>
          <Col span="24">
            <Card
              title={
                <div className="card-title" style={{ margin: "0" }}>
                  ¿Cuál de nuestros productos deseas solicitar?
                  {/* <span className="user-title">@{sessionStorage.getItem("company")}?</span> */}
                </div>
              }
            >
              {/* <CSelectWithModal
                label={values.product.label}
                name={values.product.name}
                value={values.product.value}
                onChange={setValues}
                placeholder={values.product.placeholder}
                error={values.product.error}
                options={products}
                rule={values.product.rule}
              /> */}
              <CSelect
                label={values.product.label}
                name={values.product.name}
                value={values.product.value}
                placeholder={values.product.placeholder}
                options={products}
                rule={values.product.rule}
                onChange={setValues}
              />
            </Card>
          </Col>

          <Col span="24">
            <Card
              title={
                <span style={{ margin: "0" }}>
                  Escribe tu número telefónico
                </span>
              }
            >
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <CSelect
                    label={values.lada.label}
                    name={values.lada.name}
                    value={values.lada.value}
                    placeholder="+507"
                    options={[{ value: "+507", label: "+507" }]}
                    rule={values.lada.rule}
                    onChange={setValues}
                    disabled
                  />
                </Col>
                <Col span={16}>
                  <CInput
                    name={values.phone.name}
                    value={values.phone.value}
                    placeholder={values.phone.placeholder}
                    rule={values.phone.rule}
                    onChange={setValues}
                    type="tel"
                    onKeyDown={(evt) => {
                      const regex = /^[0-9]+$/;
                      if (!regex.test(evt.key) && evt.key !== "Backspace") {
                        evt.preventDefault();
                      }
                    }}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
     
        <div className="one-button-footer">
        <div className="container-discleimer-com">
            <div className="disclaimer">
              <Checkbox
                className="dis"
                isChecked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                  console.log("Checkbox is checked:", e.target.checked); 
                }}>
                <p className="text-check">
                  Confirmo que he leído los <Link className="link" onClick={handleOpenModal}>Términos y condiciones</Link>
                </p>
              </Checkbox>
            </div>
          </div>
          <Button
            size="md"
            fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
            fontWeight="600"
            bg="UniOrange.600"
            colorScheme="UniOrange"
            color="UniOrange.50"
            width="100%"
            type="submit"
            isLoading={loading}
            disabled={loading}
            isDisabled={!allInputFilled(values) || !isChecked}
          >
            {location.state !== null ? "Ok" : "Validar"}
          </Button>
        </div>
      </form>
      {/* Modal para los términos y condiciones */}
      <Modal isOpen={isOpen} onClose={handleCloseModal} size={size} >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Términos y Condiciones</ModalHeader>
          <ModalBody >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleCloseModal}>Cerrar</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      
  
      
      </div>


  );
});

CompanySelectorForm.displayName = "CompanySelectorForm";
export default CompanySelectorForm;