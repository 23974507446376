import "./FormTemplate.scss";
const FormTemplate = ({ children, inputForm }) => {
  return (
    <div className="form-template" data-variation={inputForm ? "inputs" : ""}>
      <div className="form-body">{children}</div>
    </div>
  );
};
FormTemplate.displayname = "FormTemplate";
export default FormTemplate;
