export const BackIcon = ({ height = 10, width = 8 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0876 1.175L5.9126 0L0.912598 5L5.9126 10L7.0876 8.825L3.27093 5L7.0876 1.175Z"
        fill="currentColor"
      />
    </svg>
  );
};
