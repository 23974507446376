export const LockIcon = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
<path opacity="0.4" d="M11.3327 6.66667V5.33333C11.3327 3.49238 9.8403 2 7.99935 2C6.1584 2 4.66602 3.49238 4.66602 5.33333V6.66667" stroke="#908E8D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.99935 9.66666V11M5.86602 14H10.1327C11.2528 14 11.8128 14 12.2407 13.782C12.617 13.5903 12.9229 13.2843 13.1147 12.908C13.3327 12.4801 13.3327 11.9201 13.3327 10.8V9.86666C13.3327 8.74655 13.3327 8.1865 13.1147 7.75868C12.9229 7.38235 12.617 7.07639 12.2407 6.88464C11.8128 6.66666 11.2528 6.66666 10.1327 6.66666H5.86602C4.74591 6.66666 4.18586 6.66666 3.75803 6.88464C3.38171 7.07639 3.07575 7.38235 2.884 7.75868C2.66602 8.1865 2.66602 8.74655 2.66602 9.86666V10.8C2.66602 11.9201 2.66602 12.4801 2.884 12.908C3.07575 13.2843 3.38171 13.5903 3.75803 13.782C4.18586 14 4.74591 14 5.86602 14Z" stroke="#908E8D" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

