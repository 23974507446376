import { toast } from "react-toastify";

 const ErrorCToast = (err) => {
    const Error = err?.response?.data?.message;
    const Error2 = err?.response?.data?.error;
    const Error3 = err?.message;
    const Error4 = err?.response?.data[0]?.coincidencia;
    const Error5 = err?.response?.data?.message;
    const title = Error5 ?? Error4 ?? Error3 ?? Error2 ?? Error;
    toast.error(title, {
      position: "top-right",
      autoClose: 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  export default ErrorCToast