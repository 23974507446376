export const BackArrow = ({ height = 10, width = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 4.16667H3.69167L6.675 1.175L5.5 0L0.5 5L5.5 10L6.675 8.825L3.69167 5.83333H15.5V4.16667Z"
        fill="currentColor"
      />
    </svg>
  );
};
