export const SupportIcon = ({ height = 18, width = 18 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00008 0.666504C4.40008 0.666504 0.666748 4.39984 0.666748 8.99984C0.666748 13.5998 4.40008 17.3332 9.00008 17.3332C13.6001 17.3332 17.3334 13.5998 17.3334 8.99984C17.3334 4.39984 13.6001 0.666504 9.00008 0.666504ZM15.2167 6.59984L12.9001 7.55817C12.4751 6.42484 11.5834 5.52484 10.4417 5.10817L11.4001 2.7915C13.1501 3.45817 14.5417 4.84984 15.2167 6.59984ZM9.00008 11.4998C7.61675 11.4998 6.50008 10.3832 6.50008 8.99984C6.50008 7.6165 7.61675 6.49984 9.00008 6.49984C10.3834 6.49984 11.5001 7.6165 11.5001 8.99984C11.5001 10.3832 10.3834 11.4998 9.00008 11.4998ZM6.60842 2.78317L7.58342 5.09984C6.43342 5.5165 5.52508 6.42484 5.10008 7.57484L2.78341 6.60817C3.45841 4.84984 4.85008 3.45817 6.60842 2.78317ZM2.78341 11.3915L5.10008 10.4332C5.52508 11.5832 6.42508 12.4832 7.57508 12.8998L6.60008 15.2165C4.85008 14.5415 3.45841 13.1498 2.78341 11.3915ZM11.4001 15.2165L10.4417 12.8998C11.5834 12.4748 12.4834 11.5748 12.9001 10.4248L15.2167 11.3998C14.5417 13.1498 13.1501 14.5415 11.4001 15.2165Z"
        fill="currentColor"
      />
    </svg>
  );
};
