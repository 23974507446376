import { memo, useState } from "react";
import "./CSelect.scss";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { setFormMode } from "redux/features/newNaturalClient";
const CSelect = memo(
  ({
    id,
    name,
    value,
    onChange,
    placeholder,
    options,
    label,
    arrayKey,
    formKey,
    disabled,
    rule,
    required,
  }) => {
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const { mode } = useSelector((store) => store.newNaturalClient);
    /*   useEffect(() => {
      const getError = () => {
       if(rule) setError(rule(value));
      };
      let button: HTMLButtonElement;
      document.querySelectorAll("button").forEach((occurence) => {
        button = occurence;
        button.addEventListener("click", getError);
      });
      return () => {
        button.removeEventListener("click", getError);
      };
    }, []); */

    const handleChange = (e) => {
      if (mode === 3) dispatch(setFormMode(2)); // se cambia a mode 2 porq se esta modificando el formulario que ya estaba lleno

      if (formKey) {
        onChange((prev) => {
          const inputFormObject = prev[formKey]; // se obtiene el objeto
          const inputOnArray = inputFormObject[arrayKey]; // se obtiene la info de los inputs segun su posicion
          const input = inputOnArray[e.target.name]; // la data del input que se modifica
          input.value = e.target.value; // se actualiza el value del input
          const newDataOnArray = { ...inputOnArray, [e.target.name]: input }; // se sustituye la informacion de los inputs por lo nuevo
          const newState = [...inputFormObject]; // se copia el array origianl
          newState[arrayKey] = newDataOnArray; // se cambia el objeto modificado dentro del array
          const updateStateData = { ...prev, [formKey]: newState }; // se actualiza el objeto del array, con el nuevo
          if (rule) setError(rule(e.target.value));
          return updateStateData;
        });
        return;
      }

      if (arrayKey !== undefined) {
        onChange((prev) => {
          const inputsSection = prev[arrayKey];
          const inputObject = inputsSection[e.target.name];
          inputObject.value = e.target.value;
          const newData = { ...inputsSection, [e.target.name]: inputObject };
          const newArray = [...prev];
          newArray[arrayKey] = newData;
          if (rule) setError(rule(e.target.value));
          return newArray;
        });
      } else {
        onChange((prev) => {
          const inputObject = prev[e.target.name];
          inputObject.value = e.target.value;
          const newData = { ...prev, [e.target.name]: inputObject };
          if (rule) setError(rule(e.target.value));
          return newData;
        });
      }
    };

    const handleBlur = () => {
      if (rule) setError(rule(value));
    };
    return (
      <FormControl isRequired={required} isInvalid={error !== ""}>
        {label && (
          <FormLabel
            fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xs", xl: "xs" }}
            color="cBlueGray.800"
          >
            {label}
          </FormLabel>
        )}
        <Select
          className="select"
          fontWeight="400"
          variant="flushed"
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isInvalid={error !== ""}
          disabled={disabled}
          onBlur={handleBlur}
        >
          {options.map((res, key) => {
            return (
              <option key={key} value={res.value}>
                {res.label}
              </option>
            );
          })}
        </Select>
        {error !== "" && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
);
CSelect.displayName = "CSelect";

export default CSelect;
