import { AUTHSERVICE, SELECT_SERVICES } from "../config";

export const getRiskActivity = async () => {
  const url = "/list/riskActivity";
  return await SELECT_SERVICES().get(url);
};

export const getOccupation = async () => {
  const url = "/list/listOccupation";
  return await SELECT_SERVICES().get(url);
};

export const getSalaryRange = async () => {
  const url = "/list/salaryRange";
  return await SELECT_SERVICES().get(url);
};


export const postLaboralData = async (formData) => {
  const url = "/onboarding/laborData";
  return await AUTHSERVICE().post(url,formData);
};

