/*La función handleErrors está diseñada para gestionar 
y mostrar mensajes de error utilizando un componente de 
toast (notificación emergente) en una aplicación React. 
Esta función analiza el objeto de error recibido y extrae 
mensajes de error específicos, que luego se pasan a 
ErrorToast para su visualización*/



import { ErrorToast } from "components/Toasts/Toasts"

export const handleErrors = (err) =>{
    //Extracción de mensajes de error del objeto err
    const Error = err?.response?.data?.message
    const Error2 = err?.response?.data?.error
    const Error3 = err?.message
    //Mostrar el mensaje de error adecuado
    if (Error) return ErrorToast(Error)
    if (Error2) return ErrorToast(Error2)
    if (Error3) return ErrorToast(Error3)
}