export const StepPepIcon = ({ width = 23, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.3143 14.4062C21.7934 14.6771 22.1033 15.0807 22.244 15.6172C22.3846 16.1536 22.3195 16.6615 22.0487 17.1406L21.0487 18.8594C20.7778 19.3385 20.3742 19.6484 19.8377 19.7891C19.3013 19.9297 18.7934 19.8646 18.3143 19.5938L14.158 17.2031V22C14.158 22.5417 13.9601 23.0104 13.5643 23.4062C13.1684 23.8021 12.6997 24 12.158 24H10.158C9.61636 24 9.14761 23.8021 8.75178 23.4062C8.35595 23.0104 8.15803 22.5417 8.15803 22V17.2031L4.00178 19.5938C3.52261 19.8646 3.0148 19.9297 2.47834 19.7891C1.94188 19.6484 1.53824 19.3385 1.26741 18.8594L0.267405 17.1406C-0.00342828 16.6615 -0.0685324 16.1536 0.0720926 15.6172C0.212718 15.0807 0.522613 14.6771 1.00178 14.4062L5.15803 12L1.00178 9.59375C0.522613 9.32292 0.212718 8.91927 0.0720926 8.38281C-0.0685324 7.84635 -0.00342828 7.33854 0.267405 6.85938L1.26741 5.14062C1.53824 4.66146 1.94188 4.35156 2.47834 4.21094C3.0148 4.07031 3.52261 4.13542 4.00178 4.40625L8.15803 6.79688V2C8.15803 1.45833 8.35595 0.989583 8.75178 0.59375C9.14761 0.197917 9.61636 0 10.158 0H12.158C12.6997 0 13.1684 0.197917 13.5643 0.59375C13.9601 0.989583 14.158 1.45833 14.158 2V6.79688L18.3143 4.40625C18.7934 4.13542 19.3013 4.07031 19.8377 4.21094C20.3742 4.35156 20.7778 4.66146 21.0487 5.14062L22.0487 6.85938C22.3195 7.33854 22.3846 7.84635 22.244 8.38281C22.1033 8.91927 21.7934 9.32292 21.3143 9.59375L17.158 12L21.3143 14.4062Z"
        fill="currentColor"
      />
    </svg>
  );
};
