export const StepFinancialIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9709 11.0182C11.0291 10.3745 10.0909 9.97091 10.0909 8.94545C10.0909 7.83273 11.3018 7.42909 12.0655 7.42909C13.4945 7.42909 14.0182 8.50909 14.1382 8.89091L15.8618 8.16C15.6982 7.66909 14.9673 6.06545 13.0909 5.71636V4.36364H10.9091V5.73818C8.20364 6.34909 8.19273 8.85818 8.19273 8.96727C8.19273 11.4436 10.6473 12.1418 11.8473 12.5782C13.5709 13.1891 14.3345 13.7455 14.3345 14.7927C14.3345 16.0255 13.1891 16.5491 12.1745 16.5491C10.1891 16.5491 9.62182 14.5091 9.55636 14.2691L7.74546 15C8.43273 17.3891 10.2327 18.0327 10.9091 18.2291V19.6364H13.0909V18.2836C13.5273 18.1855 16.2545 17.64 16.2545 14.7709C16.2545 13.2545 15.5891 11.9236 12.9709 11.0182ZM2.18182 21.8182H0V15.2727H6.54545V17.4545H3.84C5.59636 20.0836 8.59636 21.8182 12 21.8182C17.4218 21.8182 21.8182 17.4218 21.8182 12H24C24 18.6327 18.6327 24 12 24C7.94182 24 4.35273 21.9818 2.18182 18.9055V21.8182ZM0 12C0 5.36727 5.36727 0 12 0C16.0582 0 19.6473 2.01818 21.8182 5.09455V2.18182H24V8.72727H17.4545V6.54545H20.16C18.4036 3.91636 15.4036 2.18182 12 2.18182C6.57818 2.18182 2.18182 6.57818 2.18182 12H0Z"
        fill="currentColor"
      />
    </svg>
  );
};
