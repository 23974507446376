import { memo, Suspense, lazy } from "react";
import { HStack, Text } from "@chakra-ui/react";
import "./InsertPinForm.scss";
import { PinInput, PinInputField } from "@chakra-ui/react";
import Icon from "../../../../../components/icons";
import { usePin } from "../../hooks/usePin";
import { Divider } from "antd";
import Support from "../../../../../assets/icons/support.svg";
import StepDots from "components/StepDots/StepDots";
const MyLazyComponent = lazy(() =>
  import("components/WhiteScreenLoader/WhiteScreenLoader")
);
const InsertPinForm = memo(() => {
  const { handlePin, loading, resendPin } = usePin();
  return (
    <div className="insert-pin-form">
      <Suspense fallback={null}>{loading && <MyLazyComponent />}</Suspense>

      <div className="body-pin">
        <form className="form">
          <Text
            fontSize={{ base: "md", sm: "md", md: "md", lg: "md", xl: "md" }}
            color={"cBlueGray.700"}
            fontWeight="500"
          >
            Ingresa tu token de gestión que recibiste en tu correo electrónico.
          </Text>
          <HStack className="custom-pin-input" justifyContent={"center"}>
            <PinInput
              colorScheme="BnpBlueHard"
              defaultValue=""
              focusBorderColor={"BnpBlueHard.800"}
              onComplete={handlePin}
              size={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
            >
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
            </PinInput>
          </HStack>

          <HStack justifyContent={"center"}>
            <Text
              fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
              fontWeight="600"
              color={"UniOrange.600"}
              cursor="pointer"
              className="custom-link"
              onClick={resendPin}
            >
              Reenviar PIN
              <Icon name="reloadIcon" />
            </Text>
          </HStack>
          <HStack justifyContent={"center"}>
            <StepDots steps={[1, 2]} actualStep={2} />
          </HStack>
        </form>
      </div>

      <div className="footer">
        {/* <div className="help-container">
          <img src={Support} alt="support" />
          <span>Necesitas ayuda con el Token?</span>
        </div>
        <Divider style={{ margin: "0" }} /> */}
        <Text color="cBlueGray.400" fontSize="xs">
          © 2024 Newtech Solutions Group
        </Text>
      </div>
    </div>
  );
});
InsertPinForm.displayName = "InsertPinForm";
export default InsertPinForm;
