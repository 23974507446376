import { memo, useRef, useState } from "react";
import "./CSelect.scss";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  useDisclosure,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import { setFormMode } from "redux/features/newNaturalClient";
import { CheckIcon, SearchIcon } from "@chakra-ui/icons";
const CSelectWithModal = memo(
  ({
    id,
    name,
    value,
    onChange,
    placeholder,
    options,
    label,
    arrayKey,
    formKey,
    disabled,
    rule,
    required,
  }) => {
    const [error, setError] = useState("");
    const dispatch = useDispatch();
    const { mode } = useSelector((store) => store.newNaturalClient);
    const selectRef = useRef(null);
    const [filter, setFilter] = useState("");
    /*   useEffect(() => {
      const getError = () => {
       if(rule) setError(rule(value));
      };
      let button: HTMLButtonElement;
      document.querySelectorAll("button").forEach((occurence) => {
        button = occurence;
        button.addEventListener("click", getError);
      });
      return () => {
        button.removeEventListener("click", getError);
      };
    }, []); */

    const handleChange = (e) => {
      if (mode === 3) dispatch(setFormMode(2)); // se cambia a mode 2 porq se esta modificando el formulario que ya estaba lleno

      if (formKey) {
        onChange((prev) => {
          const inputFormObject = prev[formKey]; // se obtiene el objeto
          const inputOnArray = inputFormObject[arrayKey]; // se obtiene la info de los inputs segun su posicion
          const input = inputOnArray[e.target.name]; // la data del input que se modifica
          input.value = e.target.value; // se actualiza el value del input
          const newDataOnArray = { ...inputOnArray, [e.target.name]: input }; // se sustituye la informacion de los inputs por lo nuevo
          const newState = [...inputFormObject]; // se copia el array origianl
          newState[arrayKey] = newDataOnArray; // se cambia el objeto modificado dentro del array
          const updateStateData = { ...prev, [formKey]: newState }; // se actualiza el objeto del array, con el nuevo
          if (rule) setError(rule(e.target.value));
          return updateStateData;
        });
        return;
      }

      if (arrayKey !== undefined) {
        onChange((prev) => {
          const inputsSection = prev[arrayKey];
          const inputObject = inputsSection[e.target.name];
          inputObject.value = e.target.value;
          const newData = { ...inputsSection, [e.target.name]: inputObject };
          const newArray = [...prev];
          newArray[arrayKey] = newData;
          if (rule) setError(rule(e.target.value));
          return newArray;
        });
      } else {
        onChange((prev) => {
          const inputObject = prev[e.target.name];
          inputObject.value = e.target.value;
          const newData = { ...prev, [e.target.name]: inputObject };
          if (rule) setError(rule(e.target.value));
          return newData;
        });
      }
    };

    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleBlur = () => {
      if (rule) setError(rule(value));
    };

    const handleClick = () => {
      disableOptions();
      onOpen();
    };

    const disableOptions = () => {
      if (selectRef.current) {
        selectRef.current.blur(); // Desactivar el enfoque para evitar que se muestren las opciones
      }
    };

    const filtrarArray = (array, filtro) => {
      const filtroLowerCase = filtro.toLowerCase();
      if (filtro === "") return array;
      return array.filter((item) =>
        item.label.toLowerCase().includes(filtroLowerCase)
      );
    };

    return (
      <FormControl isRequired={required} isInvalid={error !== ""}>
        {label && (
          <FormLabel
            fontSize={{ base: "xs", sm: "xs", md: "xs", lg: "xs", xl: "xs" }}
            color="cBlueGray.800"
          >
            {label}
          </FormLabel>
        )}
        <Select
        className="select"
        fontWeight="400"
          ref={selectRef}
     variant='flushed'
          id={id}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          isInvalid={error !== ""}
          disabled={disabled}
          onBlur={handleBlur}
          onClick={handleClick}
        >
          {filtrarArray(options, filter).map((res, key) => {
            return (
              <option key={key} value={res.value}>
                {res.label}
              </option>
            );
          })}
        </Select>

        <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader style={{ display: "flex", justifyContent: "center" }}>
             Ocupación
            </DrawerHeader>

            <DrawerBody className="custom-drawerbody">
              <div className="container-search-input-select">
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.300" />
                  </InputLeftElement>
                  <Input
                        variant='flushed'
                    className="search"
                    placeholder="Escribe una ocupación"
                    isInvalid={false}
                    onChange={(e) => setFilter(e.target.value)}
                  />
                </InputGroup>
              </div>
              <div className="options-container">
                {filtrarArray(options, filter).map((res, key) => {
                  return (
                    <div
                      className="select-modal-options"
                      key={`${res.value}${key}`}
                      onClick={() => {
                        const body = {
                          target: {
                            name,
                            value: res.value,
                          },
                        };
                        handleChange(body);
                        setFilter("");
                        onClose();
                      }}
                    >
                      <div className="left">{res.label}</div>
                      {value === res.value && (
                        <div className="right">
                          <CheckIcon />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>

        {error !== "" && <FormErrorMessage>{error}</FormErrorMessage>}
      </FormControl>
    );
  }
);
CSelectWithModal.displayName = "CSelectWithModal";

export default CSelectWithModal;
