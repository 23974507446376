import { memo } from "react";
import InsertPinForm from "./components/InsertPinForm/InsertPinForm";
import MainTemplate from "templates/MainTemplate/MainTemplate";
const InsertPin = memo(() => {
  return (
    <MainTemplate title="Ingresa el token">
      <InsertPinForm />
    </MainTemplate>
  );
});
InsertPin.displayName = "InsertPin";
export default InsertPin;
