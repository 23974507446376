export const StepsDataIcon = ({ width = 23, height = 23 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.4499 1.15002C2.8399 1.15002 2.25489 1.39235 1.82356 1.82368C1.39222 2.25501 1.1499 2.84003 1.1499 3.45002V8.62502H3.4499V3.45002H9.1999V1.15002H3.4499ZM1.1499 19.55V13.225H3.4499V19.55H9.1999V21.85H3.4499C2.8399 21.85 2.25489 21.6077 1.82356 21.1764C1.39222 20.745 1.1499 20.16 1.1499 19.55ZM13.7999 19.55V21.85H19.5499C20.1599 21.85 20.7449 21.6077 21.1762 21.1764C21.6076 20.745 21.8499 20.16 21.8499 19.55V13.225H19.5499V19.55H13.7999ZM19.5499 8.62502H21.8499V3.45002C21.8499 2.84003 21.6076 2.25501 21.1762 1.82368C20.7449 1.39235 20.1599 1.15002 19.5499 1.15002H13.7999V3.45002H19.5499V8.62502Z"
        fill="currentColor"
      />
      <path
        d="M6.9 5.75H16.1V6.9H6.9V5.75ZM5.75 9.2H17.25V10.35H5.75V9.2ZM6.9 12.65H16.1V13.8H6.9V12.65ZM5.75 16.1H17.25V17.25H5.75V16.1Z"
        fill="currentColor"
      />
    </svg>
  );
};
