import { AUTHSERVICE, SELECT_SERVICES } from "../config";

export const getSalaryFrequency = async () => {
  const url = "/list/salaryFrequency";
  return await SELECT_SERVICES().get(url);
};

export const getNumberTransaction = async () => {
  const url = "/list/numberTransactions";
  return await SELECT_SERVICES().get(url);
};

export const getTypePayment = async () => {
  const url = "/list/typePayment";
  return await SELECT_SERVICES().get(url);
};
export const getProducts = async () => {
  const url = "/list/products?idCategories=10";
  return await SELECT_SERVICES().get(url);
};


export const postStepFiveData = async (formData) => {
  const url = "/onboarding/lastStep";
  return await AUTHSERVICE().post(url,formData);
};

export const getLadaCodes = async () => {
  const url = "/list/dialCode";
  return await SELECT_SERVICES().get(url);
};
