import { handleErrors } from "helpers/handleErrors";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { handleChange } from "helpers/handleChange";
import { login } from "service/auth/auth";

export const useLogin = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(true);
  const [show, setShow] = useState(false);
  const params = useParams()
  
  const [loading, setloading] = useState(false);
  const [values, setValues] = useState({
    username: "",
  });
  const handleClick = () => setShow(!show);

 // Efecto para manejar la lógica de recarga inicial
useEffect(() => {

    const getFirtsTimeReload = () =>{
      const firstTime = sessionStorage.getItem('first-newtech')
      if(firstTime) {
        sessionStorage.clear()
      }
    }
    getFirtsTimeReload()
  
  }, []);
  
  const handleLogin = async (e) => {
    if(!params.type) return  // Efecto para manejar la lógica de recarga inicial
    e.preventDefault(); // Prevenir la acción predeterminada del formulario
    setloading(true); // Establecer el estado de carga a verdadero
    const body ={
      email: values.username,// Valor del email desde el estado
      mode: params.type ?? '', // Modo desde los parámetros, por defecto una cadena vacía
      requestId : params.requestId ?? '' // ID de solicitud desde los parámetros, por defecto una cadena vacía
    }
    try {
      await login(body);
      sessionStorage.setItem("newcheckEmail", values.username)
     /*  sessionStorage.setItem("newCheckToken", result.data.accessToken); */
      sessionStorage.setItem("mode", params.type??'');
      return navigate('/ingresar-pin')
    } catch (error) {
      handleErrors(error)
    } finally {
      setloading(false);
    }
  };

  const onChange = (e) =>
    handleChange(e, setValues);// Función para manejar cambios en el formulario
  
// Retornar los valores y funciones necesarios para el componente que use este hook
  return { handleLogin, onChange, open, setOpen, values, loading, show,handleClick };
};
