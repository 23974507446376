import { memo } from "react";
import MainTemplate from "templates/MainTemplate/MainTemplate";
import CompanySelectorForm from "./components/CompanySelectorForm/CompanySelectorForm";
const CompanySelector = memo(() => {
  return (
    <MainTemplate>
      <CompanySelectorForm />
    </MainTemplate>
  );
});
CompanySelector.displayName = "CompanySelector";
export default CompanySelector;
