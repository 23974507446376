import { AUTHSERVICE, SERVICE } from "../config";

export const login = async (data) => {
  return await SERVICE().post(`/auth/onboarding`, data);
};

export const validateOtp = async (data) => {
  return await SERVICE().post(`/auth/tokenValidate`, data);
};

export const validateOtpPhone = async (data) => {
  return await AUTHSERVICE().post(`/onboarding/validatePhone`, data);
};

export const onboardingStart = async (data) => {
  return await AUTHSERVICE().post(`/onboarding/start`, data);
};

export const onboardingTrack = async (data) => {
  return await AUTHSERVICE().post(`/onboarding/track`, data);
};


export const getLadaCodes = async () => {
  const url = "/list/dialCode";
  return await AUTHSERVICE().get(url);
};


export const resendPinService = async (data) => {
  return await AUTHSERVICE().post(`/auth/resendToken`, data);
};