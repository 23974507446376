export const PanelIcon = ({ height = 18, width = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3749 13.0832C15.7083 12.4998 15.9583 11.8332 15.9583 11.0832C15.9583 8.99984 14.2916 7.33317 12.2083 7.33317C10.1249 7.33317 8.45825 8.99984 8.45825 11.0832C8.45825 13.1665 10.1249 14.8332 12.2083 14.8332C12.9583 14.8332 13.6249 14.5832 14.2083 14.2498L16.8749 16.9165L18.0416 15.7498L15.3749 13.0832ZM12.2083 13.1665C11.0416 13.1665 10.1249 12.2498 10.1249 11.0832C10.1249 9.9165 11.0416 8.99984 12.2083 8.99984C13.3749 8.99984 14.2916 9.9165 14.2916 11.0832C14.2916 12.2498 13.3749 13.1665 12.2083 13.1665ZM9.29158 15.6665V17.3332C4.69159 17.3332 0.958252 13.5998 0.958252 8.99984C0.958252 4.39984 4.69159 0.666504 9.29158 0.666504C13.3249 0.666504 16.6832 3.53317 17.4583 7.33317H15.7333C15.1999 5.28317 13.7333 3.60817 11.7916 2.82484V3.1665C11.7916 4.08317 11.0416 4.83317 10.1249 4.83317H8.45825V6.49984C8.45825 6.95817 8.08325 7.33317 7.62492 7.33317H5.95825V8.99984H7.62492V11.4998H6.79158L2.79992 7.50817C2.69159 7.9915 2.62492 8.48317 2.62492 8.99984C2.62492 12.6748 5.61658 15.6665 9.29158 15.6665Z"
        fill="currentColor"
      />
    </svg>
  );
};
