export const TinChevron = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
 <path fill-rule="evenodd" clip-rule="evenodd" d="M5.52925 3.52858C5.7896 3.26823 6.21171 3.26823 6.47206 3.52858L10.4721 7.52858C10.7324 7.78892 10.7324 8.21103 10.4721 8.47138L6.47206 12.4714C6.21171 12.7317 5.7896 12.7317 5.52925 12.4714C5.2689 12.211 5.2689 11.7889 5.52925 11.5286L9.05784 7.99998L5.52925 4.47138C5.2689 4.21103 5.2689 3.78892 5.52925 3.52858Z" fill="currentColor"/>
    </svg>
  );
};

