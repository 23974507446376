import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ColorModeScript, extendTheme, ChakraProvider } from "@chakra-ui/react";
import { ToastContainer } from "react-toastify";
import App from "./App";
import store from "./redux/store.js";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";
const cTeal = {
  50: "#F1F9F9",
  100: "#C5E7E4",
  200: "#8FD1CB",
  300: "#55B9B0",
  400: "#27AA9E",
  500: "#009688",
  600: "#00897B",
  700: "#00796B",
  800: "#00695C",
  900: "#004D40",
};
const cYellow = {
  50: "#FEFCE8",
  100: "#FEF9C3",
  200: "#FEF08A",
  300: "#FDE047",
  400: "#FACC15",
  500: "#EAB308",
  600: "#CA8A04",
  700: "#A16207",
  800: "#854D0E",
  900: "#713F12",
};
const cRed = {
  50: "#fef2f2",
  100: "#fee2e2",
  200: "#fecaca",
  300: "#fca5a5",
  400: "#f87171",
  500: "#ef4444",
  600: "#dc2626",
  700: "#b91c1c",
  800: "#991b1b",
  900: "#7f1d1d",
};

const cLightBlue = {
  50: "#ebf8fe",
  100: "#b3e5fc",
  200: "#81d4fa",
  300: "#4fc3f7",
  400: "#29b6f6",
  500: "#03a9f4",
  600: "#039be5",
  700: "#0288d1",
  800: "#0277bd",
  900: "#01579b",
};

const cBlueGray = {
  50: "#F8F7F6",
  100: "#E2E8F0",
  200: "#E7E4E1",
  300: "#CAC6C3",
  400: "#B1ADAA",
  500: "#908E8D",
  600: "#726F6E",
  700: "#484746",
  800: "#343332",
  900: "#1F1E1E",
};

const pinkBen = {
 500: "#B90082"
 }


const cLime = {
  50: "#f7fee7",
  100: "#ecfccb",
  200: "#d9f99d",
  300: "#bef264",
  400: "#a3e635",
  500: "#84cc16",
  600: "#65a30d",
  700: "#4d7c0f",
  800: "#3f6212",
  900: "#365314",
};

const BnpBlueLight = {
  50: "#e8f1fd",
  100: "#b9d5f9",
  200: "#8ab8f5",
  300: "#5b9cf1",
  400: "#FF9A5E",
  500: "#1266d3",
  600: "#0e50a4",
  700: "#0a3975",
  800: "#062246",
  900: "#020b17",
};

const UniOrange = {
  50: "#F6FBFE",
  100: "#E6F5FD",
  200: "#B5E1F8",
  300: "#83CDF4",
  400: "#51B8F0",
  500: "#079AE9",
  600: "#068BD2",
  700: "#045C8C",
  800: "#033E5D",
  900: "#011F2F",
};
const BnpBlueHard = {
  50: "#e8e7fd",
  100: "#bbb8fa",
  200: "#8e89f6",
  300: "#6159f2",
  400: "#342aef",
  500: "#1a10d5",
  600: "#140da6",
  700: "#0f0976",
  800: "#080541",
  900: "#030218",

};

const theme = extendTheme({
  colors: {
    cTeal,
    cRed,
    cLightBlue,
    cBlueGray,
    cLime,
    cYellow,
    BnpBlueHard,
    BnpBlueLight,
    UniOrange,
    pinkBen
  },
});
ReactDOM.createRoot(document.getElementById("root")).render(
  <ChakraProvider theme={theme}>
    <ColorModeScript />
    <Provider store={store}>
      <App />
    </Provider>
    <ToastContainer />
  </ChakraProvider>
);
