export const UserIcon = ({ height = 14, width = 15 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49992 7.00016C9.34159 7.00016 10.8333 5.5085 10.8333 3.66683C10.8333 1.82516 9.34159 0.333496 7.49992 0.333496C5.65825 0.333496 4.16659 1.82516 4.16659 3.66683C4.16659 5.5085 5.65825 7.00016 7.49992 7.00016ZM7.49992 8.66683C5.27492 8.66683 0.833252 9.7835 0.833252 12.0002V13.6668H14.1666V12.0002C14.1666 9.7835 9.72492 8.66683 7.49992 8.66683Z"
        fill="currentColor"
      />
    </svg>
  );
};
