export const UsersIcon = ({ height = 12, width = 17 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00008 5.99984C7.60842 5.99984 8.91675 4.6915 8.91675 3.08317C8.91675 1.47484 7.60842 0.166504 6.00008 0.166504C4.39175 0.166504 3.08341 1.47484 3.08341 3.08317C3.08341 4.6915 4.39175 5.99984 6.00008 5.99984ZM6.00008 1.83317C6.69175 1.83317 7.25008 2.3915 7.25008 3.08317C7.25008 3.77484 6.69175 4.33317 6.00008 4.33317C5.30841 4.33317 4.75008 3.77484 4.75008 3.08317C4.75008 2.3915 5.30841 1.83317 6.00008 1.83317ZM6.04175 10.1665H2.47508C3.30008 9.74984 4.72508 9.33317 6.00008 9.33317C6.09175 9.33317 6.19175 9.3415 6.28342 9.3415C6.56675 8.73317 7.05841 8.23317 7.65008 7.83317C7.04175 7.72484 6.46675 7.6665 6.00008 7.6665C4.05008 7.6665 0.166748 8.6415 0.166748 10.5832V11.8332H6.00008V10.5832C6.00008 10.4415 6.01675 10.2998 6.04175 10.1665ZM12.2501 8.08317C10.7167 8.08317 7.66675 8.92484 7.66675 10.5832V11.8332H16.8334V10.5832C16.8334 8.92484 13.7834 8.08317 12.2501 8.08317ZM13.2584 6.5665C13.8917 6.20817 14.3334 5.53317 14.3334 4.74984C14.3334 3.59984 13.4001 2.6665 12.2501 2.6665C11.1001 2.6665 10.1667 3.59984 10.1667 4.74984C10.1667 5.53317 10.6084 6.20817 11.2417 6.5665C11.5417 6.73317 11.8834 6.83317 12.2501 6.83317C12.6167 6.83317 12.9584 6.73317 13.2584 6.5665Z"
        fill="currentColor"
      />
    </svg>
  );
};
