import { useEffect, useState } from "react";
import {
  getNumberTransaction,
  getSalaryFrequency,
  getTypePayment,
} from "service/newNaturalClient/formFive";
import { getSalaryRange } from "service/newNaturalClient/formFour";
import { incomingOptions } from "../../LaboralDataStep/hooks/useLaboralDataHook";
import { handleErrors } from "helpers/handleErrors";

export const useTransactionForm = () => {
  const [salaryRanges, setsalaryRanges] = useState([]);
  const [salaryFrequencies, setsalaryFrequencies] = useState([]);
  const [numberTransaction, setnumberTransaction] = useState([]);
  const [typePayment, settypePayment] = useState([]);
  const [STATIC_TYPE_PAYMENT, setSTATIC_PAYMENT] = useState([]);

  useEffect(() => {
    const handleGetOptions = async () => {
      try {
        const salaryRan = await getSalaryRange();
        const salaryFrec = await getSalaryFrequency();
        const numberTrans = await getNumberTransaction();
        const typePay = await getTypePayment();
        setsalaryRanges(incomingOptions(salaryRan.data));
        setsalaryFrequencies(incomingOptions(salaryFrec.data));
        setnumberTransaction(incomingOptions(numberTrans.data));
        settypePayment(incomingOptions(typePay.data));
        setSTATIC_PAYMENT(incomingOptions(typePay.data));
      } catch (error) {
        handleErrors(error);
      }
    };
    handleGetOptions();
  }, []);

  return {
    salaryRanges,
    salaryFrequencies,
    numberTransaction,
    typePayment,
    STATIC_TYPE_PAYMENT,
  };
};
