import { memo } from "react";
import "./CCheckbox.scss";
import { Checkbox, FormControl, FormLabel } from "@chakra-ui/react";
const CCheckbox = memo(
  ({ id, name, value, onChange, placeholder, label, disable }) => {
    const handleChange = (e) => {
      onChange((prev) => {
        const inputObject = prev[e.target.name];
        inputObject.value = !value;
        const newData = { ...prev, [e.target.name]: inputObject };
        return newData;
      });
    };

    return (
      <FormControl>
        {label && <FormLabel color="cBlueGray.800">{label}</FormLabel>}
        <Checkbox
          id={id}
          name={name}
          onChange={handleChange}
          isDisabled={disable}
          colorScheme={"cBlueGray"}
          isChecked={value}
        >
          {placeholder}
        </Checkbox>
      </FormControl>
    );
  }
);
CCheckbox.displayName = "CCheckbox";
export default CCheckbox;
