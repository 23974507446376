import { memo } from "react";
import InsertPinForm from "./components/InsertPinForm/InsertPinForm";
import MainTemplate from "templates/MainTemplate/MainTemplate";
const InsertPgonePin = memo(() => {
  return (
    <MainTemplate title="Ingresa el PIN de tu SMS">
      <InsertPinForm />
    </MainTemplate>
  );
});
InsertPgonePin.displayName = "InsertPgonePin";
export default InsertPgonePin;
