export const PhoneIcon = ({ height = 16, width = 16 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.75 8L15.5 4.25L11.75 0.5V2.75H8.75V5.75H11.75V8ZM13.25 11.375C12.3125 11.375 11.4125 11.225 10.5725 10.9475C10.31 10.865 10.0175 10.925 9.8075 11.1275L8.1575 12.7775C6.035 11.6975 4.295 9.965 3.215 7.835L4.865 6.1775C5.075 5.9825 5.135 5.69 5.0525 5.4275C4.775 4.5875 4.625 3.6875 4.625 2.75C4.625 2.3375 4.2875 2 3.875 2H1.25C0.8375 2 0.5 2.3375 0.5 2.75C0.5 9.7925 6.2075 15.5 13.25 15.5C13.6625 15.5 14 15.1625 14 14.75V12.125C14 11.7125 13.6625 11.375 13.25 11.375Z"
        fill="currentColor"
      />
    </svg>
  );
};
