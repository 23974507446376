import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  validateOtpPhone,
} from "../../../../service/auth/auth";
import { handleErrors } from "helpers/handleErrors";
import { SuccessToast } from "components/Toasts/Toasts";

export const usePhonePin = () => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [resendPinOffset, setresendPinOffset] = useState(false);

  const [open, setOpen] = useState(true);

  const handlePin = async (otp) => {
    setloading(true);
    const data = {
      token:otp,
      phone: sessionStorage.getItem("phone") ,
    /*   mode: sessionStorage.getItem("mode")  */
    };
    try {
      const result = await validateOtpPhone(data);

      /* sessionStorage.setItem("newCheckToken", result.data.accessToken); */
      sessionStorage.setItem("company", result.data.data.company);
     if(result.data.nextView==="1"){ // paso 0 - seleccion de producto
      return navigate('/pasos', {state:result.data})
     }
     if(result.data.nextView==="2"){ // paso 1 - vista onboarding / Estatus
      return navigate('/persona-natural/registro', {state:result.data})
     }
    } catch (error) {
      handleErrors(error);
    } finally {
      setloading(false);
    }
  };

  const resendPin = async () => {
    setloading(true);
  /*   const data: forgotPassI = {
      email: sessionStorage.getItem("newcheckEmail") as string,
    }; */
    if (resendPinOffset) {
      SuccessToast("Debe de esperar 30 segundo para volver a enviar el pin");
      return;
    }
    try {
      //await forgotPassword(data);
      SuccessToast("Se ha enviado ha su correo un nuevo pin");
      setresendPinOffset(true);
      setTimeout(() => {
        setresendPinOffset(false);
      }, 30000);
    } catch (error) {
      handleErrors(error);
    } finally {
      setloading(false);
    }
  };

  return { handlePin, open, setOpen, loading, resendPin };
};
