import { memo } from "react";
import "./StepDots.scss";

const StepDots = memo(({ steps, actualStep }) => {
  return (
    <div className="step-container">
      {steps.map((res, key) => {
        return (
          <div
            key={`${key}-step`}
            className="circle-step"
            data-variation={actualStep === res ? "active" : ""}
          ></div>
        );
      })}
    </div>
  );
});
StepDots.displayName = "StepDots";
export default StepDots;
