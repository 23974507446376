export const UploadCloud = ({ height = 20, width = 20 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.125 8.36683C15.5583 5.49183 13.0333 3.3335 10 3.3335C7.59167 3.3335 5.5 4.70016 4.45833 6.70016C1.95 6.96683 0 9.09183 0 11.6668C0 14.4252 2.24167 16.6668 5 16.6668H15.8333C18.1333 16.6668 20 14.8002 20 12.5002C20 10.3002 18.2917 8.51683 16.125 8.36683ZM11.6667 10.8335V14.1668H8.33333V10.8335H5.83333L10 6.66683L14.1667 10.8335H11.6667Z"
        fill="currentColor"
      />
    </svg>
  );
};
