import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tab: '/dashboard-demo',
  reloadPage:false,
  reloadPageRequest:false,
  reloadPageRegistered:false
}

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setTabNavigations: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setReloadPage: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  }
})

// Metodo global para actualizar el state
export const { setTabNavigations, setReloadPage } = requestSlice.actions

export default requestSlice.reducer

