/* eslint-disable react-hooks/exhaustive-deps */
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { memo } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from "@chakra-ui/react";
import { Card } from "antd";
import "../FinancialProfile.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTransactionForm } from "../hooks/useTransactionForm";
import { setFormMode } from "redux/features/newNaturalClient";
import CSelect from "components/inputs/Select/CSelect";
/* interface TransactionFormI {
  title;
  formtype: "recive" | "sent" | "service";
  reciveMoney;
  setreciveMoney;
  sentMoney;
  setsentMoney;
  checkIfHaveRepeatValues;
} */

const TransactionForm = memo(
  ({ formtype, reciveMoney, setreciveMoney, checkIfHaveRepeatValues }) => {
    const RECIVE_INITIAL_STATE = {
      recieve_period: {
        label: "Frecuencia",
        placeholder: "Seleccione una frecuencia",
        name: "recieve_period", // salary frequency
        value: "",
        required: false,
        error: "",
        visibility: "",
        type: "select",
        options: [],
        rule: (value) => {
          if (value === "") return "Debe de completar este campo";
          return "";
        },
      },

      recieve_amount: {
        label: "Monto",
        placeholder: "Seleccione el monto",
        name: "recieve_amount", // salary range
        value: "0",
        required: false,
        error: "",
        visibility: "",
        type: "select",
        options: [],
        rule: (value) => {
          if (value === "") return "Debe de completar este campo";
          if (value === "0") return "Debe de completar este campo";
          return "";
        },
      },
      recieve_transaction_amount: {
        label: "Transacciones",
        placeholder: "Seleccione la cantidad",
        name: "recieve_transaction_amount", // number transaction
        value: "0",
        required: false,
        error: "",
        visibility: "",
        type: "select",
        disabled: false,
        options: [],
        rule: (value) => {
          if (value === "") return "Debe de completar este campo";
          if (value === "0") return "Debe de completar este campo";
          return "";
        },
      },
      recieve_transaction_type: {
        label: "Tipo de pago",
        placeholder: "Seleccione el tipo de pago",
        name: "recieve_transaction_type", // type payment
        value: "",
        required: false,
        error: "",
        visibility: "",
        type: "radio",
        options: [],
        rule: (value) => {
          if (value === "") return "Debe de completar este campo";
          return "";
        },
      },
    };
    const dispatch = useDispatch();
    const { mode } = useSelector((store) => store.newNaturalClient);
    const { salaryRanges, salaryFrequencies, numberTransaction, typePayment } =
      useTransactionForm();

    const addRow = () => {
      if (mode === 3) dispatch(setFormMode(2));
      setreciveMoney((prev) => {
        const newArray = [...prev];
        newArray.push(RECIVE_INITIAL_STATE);
        return newArray;
      });
    };

    const removeRow = (key) => {
      if (mode === 3) dispatch(setFormMode(2));
      setreciveMoney((prev) => {
        const newArray = [...prev];
        newArray.splice(key, 1);
        return newArray;
      });
    };

    return (
      <>
        {reciveMoney?.map((res, key) => {
          return (
            <Card key={key} >
              <div
                key={`${formtype}-${key}`}
                className="row wrap"
                style={{ marginBottom: "16px" }}
              >
                <div className="col">
                  <CSelect
                    label={res.recieve_period.label}
                    name={res.recieve_period.name}
                    value={res.recieve_period.value}
                    onChange={setreciveMoney}
                    placeholder={res.recieve_period.placeholder}
                    error={res.recieve_period.error}
                    rule={res.recieve_period.rule}
                    options={salaryFrequencies}
                    arrayKey={key}
                  />
                </div>
                <div className="col">
                  <CSelect
                    label={res.recieve_amount.label}
                    name={res.recieve_amount.name}
                    value={res.recieve_amount.value}
                    onChange={setreciveMoney}
                    placeholder={res.recieve_amount.placeholder}
                    error={res.recieve_amount.error}
                    type={res.recieve_amount.type}
                    disabled={res.recieve_amount.disabled}
                    arrayKey={key}
                    options={salaryRanges}
                    rule={res.recieve_amount.rule}
                  />
                </div>
                <div className="col">
                  <CSelect
                    label={res.recieve_transaction_amount.label}
                    name={res.recieve_transaction_amount.name}
                    value={res.recieve_transaction_amount.value}
                    onChange={setreciveMoney}
                    placeholder={res.recieve_transaction_amount.placeholder}
                    error={res.recieve_transaction_amount.error}
                    options={numberTransaction}
                    arrayKey={key}
                    rule={res.recieve_transaction_amount.rule}
                  />
                </div>
                <div className="col">
                  <CSelect
                    label={res.recieve_transaction_type.label}
                    name={res.recieve_transaction_type.name}
                    value={res.recieve_transaction_type.value}
                    onChange={setreciveMoney}
                    placeholder={res.recieve_transaction_type.placeholder}
                    error={res.recieve_transaction_type.error}
                    options={typePayment}
                    arrayKey={key}
                    rule={res.recieve_transaction_type.rule}
                  />
                </div>
                <div className="col">
                  <div className="row">
                    {key !== 0 && (
                      <div
                        className="delete action-icon"
                        onClick={() => removeRow(key)}
                      >
                        <DeleteIcon />
                      </div>
                    )}
                    {key === reciveMoney.length - 1 &&
                      key < typePayment.length - 1 && (
                        <div className=" plus action-icon" onClick={addRow}>
                          <AddIcon />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </Card>
          );
        })}

        {checkIfHaveRepeatValues(reciveMoney) && (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Alert
              style={{
                borderRadius: "12px",
                width: "calc(100% - 40px)",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
                position: "fixed",
                top: "20px",
                left: "20px",
                zIndex: "20",
              }}
              status="error"
            >
              <AlertIcon />
              <AlertTitle>¡Tipo de pago repetido!</AlertTitle>
              <AlertDescription>
                No pueden haber tipos de pago repetidos. Por favor, escoja otro
                distinto
              </AlertDescription>
            </Alert>
          </div>
        )}
      </>
    );
  }
);
TransactionForm.displayName = "TransactionForm";
export default TransactionForm;
