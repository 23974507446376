import { memo } from "react";
import WelcomeTemplate from "templates/WelcomeTemplate/WelcomeTemplate";
import WelcomeO from "../../../assets/icons/welcome-one.svg"
import PointWO from "../../../assets/icons/point-welO.svg"
import { Text } from "@chakra-ui/layout";
import { useNavigate, useParams } from "react-router";
import "./Welcome.scss"
import { Button } from "@chakra-ui/button";
const WelcomeOne = memo(() => {

  const params = useParams()


  sessionStorage.setItem("requestID:", params.requestId ?? '');
  const navigate = useNavigate();
  const handleClick = () => {
    return navigate("/welcome-two")
  }
  return (
    <WelcomeTemplate onClick={handleClick}>
      <img src={WelcomeO} alt="welcome-one" />
      <img src={PointWO} alt="welcome-one" />
      <Text color="cBlueGray.700" fontSize="14" fontWeight="500">Bienvenid@ a la nueva era de pagos de Panamá</Text>

       {/* <input accept="image/*" id="icon-button-file" type="file" capture="environment"/>  */}
    </WelcomeTemplate>
  );
});
WelcomeOne.displayName = "WelcomeOne";
export default WelcomeOne;