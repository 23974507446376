import { createSlice } from '@reduxjs/toolkit'



const initialState = {
  searchBar: '',
  requestType: 'completed',
  startSearch:false,
  userDetail:null,
  loadingRequestData:true
}

export const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    setSearchBar: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setRequestType: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setStartSearch: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setuserDetail: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
    setLoadingRequestData: (state, action) => {
      return {
        ...state,
        ...action.payload
      }
    },
  }
})

// Metodo global para actualizar el state
export const { setSearchBar, setRequestType, setStartSearch, setuserDetail,setLoadingRequestData } = requestSlice.actions

export default requestSlice.reducer

