export const WIcon = ({ height = 48, width = 48 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      
      <path opacity="0.4" d="M30.6663 24H17.333" stroke="#B90082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M22.333 29L17.333 24L22.333 19" stroke="#B90082" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};


