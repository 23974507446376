import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormTrackInfo,
  setNewNaturalStep,
} from "redux/features/newNaturalClient";
import { getAllValuesFromArrayForm } from "helpers/getAllValuesFromForm";
import { postStepFiveData } from "service/newNaturalClient/formFive";
import { handleErrors } from "helpers/handleErrors";
const structureArrayInfoFromBack = (trackData, formState) => {
  const finishArray = [];
  for (let x = 0; x < trackData.length; x++) {
    const element = trackData[x];
    const objectKeys = Object.keys(element);
    const newFormState = { ...formState };
    for (let y = 0; y < objectKeys.length; y++) {
      const keys = objectKeys[y];
      const dataFromBack = element[keys];
      newFormState[keys] = { ...newFormState[keys], value: dataFromBack };
    }
    finishArray.push(newFormState);
  }
  return finishArray;
};

export const useFinancialProfileHook = () => {
  const { trackData } = useSelector((store) => store.newNaturalClient);
  const [loading, setloading] = useState(false);
  const RECIVE_INITIAL_STATE = {
    recieve_period: {
      label: "Frecuencia",
      placeholder: "Seleccione",
      name: "recieve_period", // salary frequency
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },

    recieve_amount: {
      label: "Monto",
      placeholder: "Seleccione",
      name: "recieve_amount", // salary range
      value: "0",
      required: false,
      error: "",
      visibility: "",
      type: "select",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";

        if (value === "0") return "Debe de completar este campo";
        return "";
      },
    },
    recieve_transaction_amount: {
      label: "Transacciones",
      placeholder: "Seleccione la cantidad",
      name: "recieve_transaction_amount", // number transaction
      value: "0",
      required: false,
      error: "",
      visibility: "",
      type: "select",
      disabled: false,
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        if (value === "0") return "Debe de completar este campo";
        return "";
      },
    },
    recieve_transaction_type: {
      label: "Tipo de pago",
      placeholder: "Seleccione",
      name: "recieve_transaction_type", // type payment
      value: "",
      required: false,
      error: "",
      visibility: "",
      type: "radio",
      options: [],
      rule: (value) => {
        if (value === "") return "Debe de completar este campo";
        return "";
      },
    },
  };
  const initialRecieveMoneyState = [RECIVE_INITIAL_STATE];

  const [reciveMoney, setreciveMoney] = useState(initialRecieveMoneyState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);

    return () => {
      setreciveMoney(initialRecieveMoneyState);
    };
  }, []);

  const handleInitialStateInfo = () => {
    const formPersonal = structureArrayInfoFromBack(
      trackData.data.quota,
      RECIVE_INITIAL_STATE
    );
    setreciveMoney(formPersonal);
  };

  useEffect(() => {
    if (Object.keys(trackData.data).length > 1) handleInitialStateInfo();
  }, [trackData.data]);

  const handleSubmit = async (e) => {
    setloading(true);
    e.preventDefault();
    const data = getAllValuesFromArrayForm(reciveMoney);
    const body = {
      quota: data,
    };
    try {
      const res = await postStepFiveData(body);
      
      dispatch(setFormTrackInfo(res.data));
      dispatch(setNewNaturalStep(parseInt(res.data.nextView)));
      navigate(".", { state: res.data });
    } catch (error) {
      handleErrors(error);
    } finally {
      setloading(false);
    }
  };

  return {
    reciveMoney,
    setreciveMoney,
    handleSubmit,
    loading,
  };
};
