export const TrashIcon = ({ height = 16, width = 12 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999837 13.8333C0.999837 14.75 1.74984 15.5 2.6665 15.5H9.33317C10.2498 15.5 10.9998 14.75 10.9998 13.8333V3.83333H0.999837V13.8333ZM11.8332 1.33333H8.9165L8.08317 0.5H3.9165L3.08317 1.33333H0.166504V3H11.8332V1.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};
