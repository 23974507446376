import { memo, useEffect } from "react";
import { Button } from "@chakra-ui/react";
import "./AddresDataStep.scss";
import { useAddressDataStep } from "./hooks/useAddressDataStep";
import { allInputFilledWithRequired } from "helpers/validateAllInputsFilled";
import Navbar from "components/Navbar/Navbar";
import HomeAdress from "./components/HomeAdress";

const AddressDataStep = memo(() => {

  // Se utiliza para manejar la lógica del formulario
  const { homeAdress, setHomeAdress, handleSubmit, loading } =
    useAddressDataStep();
  // Efecto secundario para desplazar la ventana al inicio cuando se monta el componente
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
     // Formulario que se envía al llamar a handleSubmit
    <form onSubmit={handleSubmit} className="natural-client-form step1">
      <Navbar title="Datos de residencia" noBackbutton={true} />

      <HomeAdress homeAdress={homeAdress} setHomeAdress={setHomeAdress} />
      <div className="one-button-footer background">
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.500"
          colorScheme="UniOrange"
          color="UniOrange.50"
          type="submit"
          isLoading={loading}
          id="submit-button"
          /*      isDisabled={!allInputFilled({...personalValues,...homeAdress,...kycClient})} */
          isDisabled={!allInputFilledWithRequired({ ...homeAdress })}
        >
          Siguiente
        </Button>
      </div>
    </form>
  );
});
AddressDataStep.displayName = "AddressDataStep";
export default AddressDataStep;
