import { memo, Suspense, lazy } from "react";
import { HStack, Text } from "@chakra-ui/react";
import "./InsertPinForm.scss";
import { PinInput, PinInputField } from "@chakra-ui/react";
import Icon from "../../../../../components/icons";
import { usePhonePin } from "../../hooks/usePhonePin";

import StepDots from "components/StepDots/StepDots";
const MyLazyComponent = lazy(() =>
  import("components/WhiteScreenLoader/WhiteScreenLoader")
);

/*Es un formulario para ingresar un PIN recibido en un
número telefónico. Utiliza Suspense para manejar el 
estado de carga, muestra el número de teléfono desde 
essionStorage,  y proporciona una opción para reenviar el PIN*/
const InsertPinForm = memo(() => {

  //Extrae handlePin, loading, y resendPin del hook usePhonePin
  const { handlePin, loading, resendPin } = usePhonePin();
  return (
    <div className="insert-pin-form">
      <Suspense fallback={null}>{loading && <MyLazyComponent />}</Suspense>

      <div className="body-pin">
        <form className="form">
          <Text
            fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
            color={"cBlueGray.700"}
            fontWeight="500"
          >
            Ingresa el PIN de 4 dígitos que recibiste en tu número telefónico{" "}
            <strong>{sessionStorage.getItem("phone")}</strong>
          </Text>
          <HStack className="custom-pin-input" justifyContent={"center"}>
            <PinInput
              onComplete={handlePin}
              size={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
            >
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
              <PinInputField className="pin" />
            </PinInput>
          </HStack>

          {/* <HStack justifyContent={"center"}>
            <Text
              fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
              fontWeight="600"
              color={"UniOrange.600"}
              cursor="pointer"
              className="custom-link"
              onClick={resendPin}
            >
              Reenviar PIN
              <Icon name="reloadIcon" />
            </Text>
          </HStack> */}
          <HStack justifyContent={"center"}>
            <StepDots steps={[1, 2]} actualStep={2} />
          </HStack>
        </form>
      </div>

      <div className="footer">
        {/* <div className="help-container">
          <img src={Support} alt="support" />
          <span>Necesitas ayuda con el Token?</span>
        </div>
        <Divider style={{ margin: "0" }} /> */}
        <Text color="cBlueGray.400" fontSize="xs">
          © 2024 Newtech Solutions Group
        </Text>
      </div>
    </div>
  );
});
InsertPinForm.displayName = "InsertPinForm";
export default InsertPinForm;
