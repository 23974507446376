import { memo, useState } from "react";
import { Button, Checkbox, Heading } from "@chakra-ui/react";
import { Card } from "antd";
import { useLaboralDataHook } from "../hooks/useLaboralDataHook";
import CSelect from "components/inputs/Select/CSelect";
import CInput from "components/inputs/Input/CInput";
import CRadioInput from "components/inputs/RadioInput/CRadioInput";
import { allInputFilled } from "helpers/validateAllInputsFilled";
import Navbar from "components/Navbar/Navbar";
import CSelectWithModal from "components/inputs/SelectWithModal/CSelectWithModal";
import "./LaboralDataForm.scss";
import CSelectWithModalAct from "components/inputs/SelectWithModal/CSelectWithModalActivity";
const LaboralDataForm = memo(() => {
  const { laboralData, setlaboralData, loading, handleSubmit } =
    useLaboralDataHook();
  const [isChecked, setIsChecked] = useState(false);
  return (
    <form className="form" onSubmit={handleSubmit}>
      <Navbar title="Datos laborales" />

      <Card
        style={{ marginBottom: "10px" }}
        title={
          <Heading
           className="title-data"
          >
            Ingrese su información laboral
          </Heading>
        }
      >
        <div className="row wrap">
          <div className="col">
            <CSelectWithModalAct
              label={laboralData.activity.label}
              name={laboralData.activity.name}
              value={laboralData.activity.value}
              onChange={setlaboralData}
              placeholder={laboralData.activity.placeholder}
              error={laboralData.activity.error}
              options={laboralData.activity.options}
              rule={laboralData.activity.rule}
            />
          </div>
          <div className="col">
            <CSelectWithModal
              label={laboralData.profession.label}
              name={laboralData.profession.name}
              value={laboralData.profession.value}
              onChange={setlaboralData}
              placeholder={laboralData.profession.placeholder}
              error={laboralData.profession.error}
              options={laboralData.profession.options}
              rule={laboralData.profession.rule}
            />
          </div>
          <div className="col">
            <CInput
              label={laboralData.companyWork.label}
              name={laboralData.companyWork.name}
              value={laboralData.companyWork.value}
              onChange={setlaboralData}
              placeholder={laboralData.companyWork.placeholder}
              error={laboralData.companyWork.error}
              type={laboralData.companyWork.type}
              rule={laboralData.companyWork.rule}
            />
          </div>
          <div className="col">
            <CSelect
              label={laboralData.amount.label}
              name={laboralData.amount.name}
              value={laboralData.amount.value}
              onChange={setlaboralData}
              placeholder={laboralData.amount.placeholder}
              error={laboralData.amount.error}
              type={laboralData.amount.type}
              disabled={laboralData.amount.disabled}
              options={laboralData.amount.options}
              rule={laboralData.amount.rule}
            />
          </div>

          <div className="col">
            <CRadioInput
              label={laboralData.otherIncome.label}
              name={laboralData.otherIncome.name}
              value={laboralData.otherIncome.value}
              onChange={setlaboralData}
              placeholder={laboralData.otherIncome.placeholder}
              error={laboralData.otherIncome.error}
              disabled={laboralData.otherIncome.disabled}
              radioOptions={laboralData.otherIncome.options}
              rule={laboralData.otherIncome.rule}
            />
          </div>
          {laboralData.otherIncome.value === "yes" && (
            <div className="col">
              <CSelect
                label={laboralData.otherAmount.label}
                name={laboralData.otherAmount.name}
                value={laboralData.otherAmount.value}
                onChange={setlaboralData}
                placeholder={laboralData.otherAmount.placeholder}
                error={laboralData.otherAmount.error}
                disabled={laboralData.otherAmount.disabled}
                options={laboralData.otherAmount.options}
              />
            </div>
          )}
        </div>
      </Card>
      <div className="container-discleimer">
          <div className="disclaimer">
            <Checkbox className="dis" isChecked={isChecked} onChange={(e) => setIsChecked(e.target.checked)}>
              <p className="text-check">Confirmo que he leído y entendido la siguiente declaración:</p>
            </Checkbox>
          </div>
          <p className="text-dis">Esta declaración jurada de ingreso es presentada bajo gravedad de juramento por el cliente, quien asegura que la información proporcionada es veraz y no está relacionada con actividades ilegales. El Banco se reserva el derecho de verificar la información y de solicitar aclaraciones adicionales.
            Cualquier cambio en los datos deberá ser informado de inmediato al Banco.</p>
        </div>

      <div className="one-button-footer background">
     
        <Button
          size={{ base: "md", sm: "md", md: "lg", lg: "lg", xl: "lg" }}
          fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "sm", xl: "sm" }}
          fontWeight="600"
          bg="UniOrange.500"
          colorScheme="UniOrange"
          color="UniOrange.50"
          type="submit"
          isLoading={loading}
          id="submit-button"
          isDisabled={!allInputFilled(laboralData) || !isChecked}
        >
          Siguiente
        </Button>


      </div>

    </form>
  );
});
LaboralDataForm.displayName = "LaboralDataForm";
export default LaboralDataForm;
