export const PassIconError = ({ height = 20, width = 19 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00002 0.666504C4.39169 0.666504 0.666687 4.3915 0.666687 8.99984C0.666687 13.6082 4.39169 17.3332 9.00002 17.3332C13.6084 17.3332 17.3334 13.6082 17.3334 8.99984C17.3334 4.3915 13.6084 0.666504 9.00002 0.666504ZM9.00002 15.6665C5.32502 15.6665 2.33335 12.6748 2.33335 8.99984C2.33335 5.32484 5.32502 2.33317 9.00002 2.33317C12.675 2.33317 15.6667 5.32484 15.6667 8.99984C15.6667 12.6748 12.675 15.6665 9.00002 15.6665ZM11.9917 4.83317L9.00002 7.82484L6.00835 4.83317L4.83335 6.00817L7.82502 8.99984L4.83335 11.9915L6.00835 13.1665L9.00002 10.1748L11.9917 13.1665L13.1667 11.9915L10.175 8.99984L13.1667 6.00817L11.9917 4.83317Z"
        fill="#EF4444"
      />
    </svg>
  );
};
