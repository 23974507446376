import { memo } from "react";
import { Heading } from "@chakra-ui/react";
import { Card } from "antd";
import CInput from "components/inputs/Input/CInput";
import CCheckbox from "components/inputs/CheckBox/CCheckbox";

const PepForm = memo(({ pepValues, setpepValues }) => {
  return (
    <form className="form">
      <Card
        title={
          <Heading
          textAlign="start"
            as="h4"
            fontSize={{ base: "lg", sm: "lg", md: "lg", lg: "lg", xl: "lg" }}
            color={"cBlueGray.700"}
            style={{ margin: "0", padding: "0 0px" }}
          >
            Relación PEP
          </Heading>
        }
      >
        <div className="row wrap">
          <div className="col">
            <CInput
       
              label={pepValues.positionPep.label}
              name={pepValues.positionPep.name}
              value={pepValues.positionPep.value}
              onChange={setpepValues}
              placeholder={pepValues.positionPep.placeholder}
              error={pepValues.positionPep.error}
              type={pepValues.positionPep.type}
              rule={pepValues.positionPep.rule}
            />
          </div>
          <div className="col ">
            <CInput
              label={pepValues.DateStart.label}
              name={pepValues.DateStart.name}
              value={pepValues.DateStart.value}
              onChange={setpepValues}
              placeholder={pepValues.DateStart.placeholder}
              error={pepValues.DateStart.error}
              type={pepValues.DateStart.type}
              rule={pepValues.DateStart.rule}
              min={pepValues.DateStart.min}
              max={pepValues.DateStart.max}
            />
          </div>
          <div className="col">
            <CInput
              label={pepValues.DateEnd.label}
              name={pepValues.DateEnd.name}
              value={pepValues.DateEnd.value}
              onChange={setpepValues}
              placeholder={pepValues.DateEnd.placeholder}
              error={pepValues.DateEnd.error}
              type={pepValues.DateEnd.type}
              rule={pepValues.DateEnd.rule}
              min={pepValues.DateEnd.min}
              max={pepValues.DateEnd.max}
              disabled={pepValues.DateEnd.disabled}
            />
          </div>
          <div className="col ">
            <CCheckbox
              label={pepValues.actualyOnCharge.label}
              name={pepValues.actualyOnCharge.name}
              value={pepValues.actualyOnCharge.value}
              onChange={setpepValues}
              placeholder={pepValues.actualyOnCharge.placeholder}
              error={pepValues.actualyOnCharge.error}
              type={pepValues.actualyOnCharge.type}
              rule={pepValues.actualyOnCharge.rule}
            />
          </div>
        </div>
      </Card>
    </form>
  );
});
PepForm.displayName = "PepForm";
export default PepForm;
