export const AdverIcon = ({ height = 20, width = 22 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4.49L18.53 17.5H3.47L11 4.49ZM11 0.5L0 19.5H22L11 0.5Z"
        fill="currentColor"
      />
      <path d="M12 14.5H10V16.5H12V14.5Z" fill="currentColor" />
      <path d="M12 8.5H10V13.5H12V8.5Z" fill="currentColor" />
    </svg>
  );
};
